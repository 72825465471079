import React from 'react';
import styled, { css } from 'styled-components';

import {
  Box, Button, TextInput, TextArea, CheckBox,
} from 'grommet';
import { DropButton } from '@Components/Control';

const StyledFormFieldContainer = styled(Box)`
  border-radius: 0.35rem;
  background: ${({ bgColor }) => bgColor ?? 'white'};
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
`;

const StyledFormSelect = styled((props) => <DropButton {...props} />)`
  width: 100%;
  box-shadow: ${({ noShadow }) => (noShadow ? 'none' : '0px 2px 7px 0px rgba(0, 0, 0, 0.12)')};
  border-radius: 0.35rem;

  > button {
    background: ${({ bgColor }) => bgColor ?? 'white'};
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.35rem;
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
    width: 100%;

    &:focus {
      border: ${({ highlight }) => `1px solid ${highlight}`};
    }

    > div {
      border-radius: 0.35rem;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
`;

const StyledFormSelectMenu = styled(Button)`
  padding: 0.75rem;
  border-radius: 6px;

  &:hover {
    color: ${({ highlightText }) => highlightText};
  }
`;

const StyledModalActivateButton = styled(Button)`
  width: 100%;
  border-radius: 0.35rem;
  border: ${({ modalOpen, highlight, borderColor }) => (modalOpen ? `1px solid ${highlight}` : `1px solid ${borderColor}`)};
  padding: ${({ lessPad }) => (lessPad ? '0.5rem 1rem' : '0.65rem 1rem')};

  img {
    max-width: 18px;
  }
`;

const StyledModalSelectButton = styled(Button)`
  width: ${({ customWidth }) => (customWidth || '10.5rem')};
  height: ${({ customHeight }) => (customHeight || '2.85rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
  color: ${({ primaryText }) => primaryText};
  background-color: ${({ bgColor }) => bgColor ?? 'white'};

  &:hover {
    border: ${({ buttonHighlight }) => `1px solid ${buttonHighlight}`};
    color: ${({ buttonHighlight }) => buttonHighlight};
    background-color: ${({ buttonBg }) => buttonBg};
  }

  ${({ selected, buttonHighlight, buttonBg }) => selected && `
    border: ${`1px solid ${buttonHighlight}`};
    color: ${buttonHighlight};
    background-color: ${buttonBg};
  `}

  @media (max-width: 768px) {
    width: 8.75rem;
  }
`;

const StyledRaisedContainer = styled(Box)`
  border-radius: 0.35rem;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
  background-color: ${({ bgColor }) => bgColor ?? 'white'};
`;

const StyledFormInputClear = styled(Button)`
  position: absolute;
  padding: 0.5rem;
  top: 0;
  bottom: 0;
  right: 0;
`;

const inputStyle = css`
  background: ${({ bgColor }) => bgColor ?? 'white'};
  font-size: 0.8rem;
  border: ${({ borderColor }) => (borderColor ? `solid 1px ${borderColor}` : 'solid 1px #D0D0D0')};
  padding: 0.6rem 1rem;
  border-radius: 0.35rem;
  width: 100%;

  &:focus {
    border-color: ${({ highlight }) => highlight};
  }
`;

const StyledFormInput = styled(TextInput)`${inputStyle}`;

const StyledFormTextArea = styled(TextArea)`${inputStyle}`;

const StyledUploadContainer = styled(Box)`
  position: relative;

  * { cursor: pointer; }

  &:hover > button {
    box-shadow: none;
    color: white;
    border: ${({ highlight }) => `1px solid ${highlight}`};
  }
`;

const StyledUploadInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
`;

const StyledFormCloseContainer = styled(Box)`
  svg { padding-top: 0.1rem };

  &:hover {
    span {
      color: ${({ highlight }) => highlight};
    }

    svg {
      stroke: ${({ highlight }) => highlight};
      fill: ${({ highlight }) => highlight};
    }
  }
`;

const StyledSmallButton = styled(Button)`
  backface-visibility: hidden;

  &:disabled { opacity: 1; }
  &:hover:not(:disabled) > div {
    border: ${({ highlight }) => `1px solid ${highlight}`};
  }

  &:focus:not(:disabled) > div {
    border: ${({ highlight }) => `1px solid ${highlight}`};
  }
`;

const StyledCheckBox = styled(CheckBox)`
  backface-visibility: hidden;

  + div {
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
    height: 1.75rem;
    width: 1.75rem;
  }

  &:hover + div {
    border: ${({ focusHighlight }) => `1px solid ${focusHighlight}`} !important;
  }

  + div > svg {
    stroke: ${({ checked, color }) => (checked ? color : 'transparent')};
    width: 1.45rem;
    height: 1.45rem;
  }
`;

export {
  StyledFormFieldContainer,
  StyledFormSelectMenu,
  StyledFormSelect,
  StyledRaisedContainer,
  StyledFormInput,
  StyledFormInputClear,
  StyledModalActivateButton,
  StyledModalSelectButton,
  StyledFormCloseContainer,
  StyledFormTextArea,
  StyledSmallButton,
  StyledUploadContainer,
  StyledUploadInput,
  StyledCheckBox,
};

import styled from 'styled-components';

import { Box, Text } from 'grommet';

const StyledAccountInfoContainer = styled(Box)`
  background: white;
  font-size: 1rem;
  border: solid 1px #D0D0D0;
  color: var(--primary-text);
  padding: 0.65rem 1rem;
  border-radius: 5px;

  &:hover, &:focus, &:focus-within {
    border: ${({ focusHighlight }) => `solid 1px ${focusHighlight}`};
  }
`;

const StyledStatusBadge = styled(Box)`
  text-align: center;
  polyline { stroke-width: 3px; }
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  height: fit-content;
  width: fit-content;

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    max-height: 2.25rem;
  }
`;

const StyledText = styled(Text)`
  line-height: 1.5;
`;

export {
  StyledAccountInfoContainer,
  StyledStatusBadge,
  StyledText,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Box, Text, Button,
} from 'grommet';
import { FormNext } from 'grommet-icons';

import { formatStripePrice } from '@Helpers/util';


const StyledProductContainer = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  color: ${({ primaryText }) => primaryText};
`;

const StyledPriceSelectButton = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
  color: ${({ primaryText }) => primaryText};
  background-color: white;

  &:hover {
    border: ${({ buttonHighlight }) => `1px solid ${buttonHighlight}`};
    color: ${({ buttonHighlight }) => buttonHighlight};
    background-color: ${({ buttonBg }) => buttonBg};
  }

  ${({ selected, buttonHighlight, buttonBg }) => selected && `
    border: ${`1px solid ${buttonHighlight}`};
    color: ${buttonHighlight};
    background-color: ${buttonBg};
  `}
`;

const ProductSelector = ({
  activeTier,
  tierPrices,
  setSelectedPrice,
  selectedPrice,
  highlight,
  selectButtonBg,
  textColor,
}) => {
  const pricesCopy = tierPrices && [...tierPrices];
  const orderedData = pricesCopy?.sort((a, b) => (
    parseInt(a.metadata.order, 10) - parseInt(b.metadata.order, 10)
  ));

  return (
    <Box direction="column" gap="1rem">
      <StyledProductContainer
        background="white"
        primaryText={textColor}
        gap="1rem"
      >
        <Box direction="column" gap="0.5rem" width="100%">
          <Text size="1.25rem" color={textColor} weight={600}>
            {activeTier.name}
          </Text>
          <Box direction="row" gap="0.5rem" align="center">
            <FormNext size="1rem" color={highlight} />
            <Text size="1rem" color={textColor} weight={500}>
              {`Monitoring of up to ${activeTier.metadata.searchLimit} of your videos`}
            </Text>
          </Box>
          <Box direction="row" gap="0.5rem" align="center">
            <FormNext size="1rem" color={highlight} />
            <Text size="1rem" color={textColor} weight={500}>
              Unlimited takedown requests
            </Text>
          </Box>
        </Box>
        <Box direction="column" gap="0.5rem" width="100%">
          {orderedData && orderedData.map((price) => (
            <StyledPriceSelectButton
              key={price.priceId}
              primaryText={textColor}
              borderColor={highlight}
              buttonHighlight={highlight}
              buttonBg={selectButtonBg}
              selected={selectedPrice?.priceId === price.priceId}
              onClick={() => setSelectedPrice(price)}
            >
              <Box direction="column" gap="0.5rem" pad="1rem" width="100%">
                <Box direction="row" justify="between" align="center" width="100%">
                  <Text size="1rem" color={textColor} weight={600}>
                    {price.metadata.title}
                  </Text>
                  <Text size="1rem" color={textColor} weight={500}>
                    {formatStripePrice(price.unitAmount, price.currency)}
                    {' '}
                    per month
                  </Text>
                </Box>
                <Text size="1rem" color={textColor} weight={400}>
                  {price.metadata.description}
                </Text>
                <Text size="1rem" color="#038BFC" weight={400}>
                  Includes
                  {' '}
                  {price.metadata.displayPlatforms}
                </Text>
              </Box>
            </StyledPriceSelectButton>
          ))}
        </Box>
      </StyledProductContainer>
    </Box>
  );
};

ProductSelector.propTypes = {
  activeTier: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      searchLimit: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
    }).isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  tierPrices: PropTypes.arrayOf(PropTypes.shape({
    priceId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    unitAmount: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      order: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
      platforms: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  selectedPrice: PropTypes.shape({
    priceId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    unitAmount: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      order: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
      platforms: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }),
  setSelectedPrice: PropTypes.func.isRequired,
  highlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default ProductSelector;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import { AppButton } from '@Components/Control';

import { sendPasswordResetInstructionsRequest } from '@Actions';

import { paths } from '@Components/configs';

const ForgotPasswordPage = ({
  small,
  sendPasswordResetInstructions,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
  },
}) => {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    sendPasswordResetInstructions(email, paths.root);
  };

  return (
    <SubmissionLayout
      withBackButton
      seoConfig={null}
      title="Forgot your password?"
      bgColor={bgColor}
      bgImage={bgImage}
      textColor={textColor}
      small={small}
    >
      <StyledSubmissionField required name="email" htmlFor="email" type="email">
        <StyledSubmissionInput
          plain
          name="email"
          type="email"
          placeholder="Email"
          highlight={highlight}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </StyledSubmissionField>
      <AppButton
        overrideHover
        level="authSubmit"
        onClick={(e) => handleSubmit(e)}
        disabled={!email || (email && !email.includes('@'))}
        bgColor={highlight}
        color="white"
        label="Recover Account"
        alignSelf="center"
      />
    </SubmissionLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    sendPasswordResetInstructions: sendPasswordResetInstructionsRequest,
  }, dispatch);
}

ForgotPasswordPage.propTypes = {
  small: PropTypes.bool.isRequired,
  sendPasswordResetInstructions: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Box } from 'grommet';

import { AppButton } from '@Components/Control';
import {
  NoDataPlaceholder,
  TableWrapper,
} from '../SharedComponents';
import { StyledModalSelectButton } from '../NewSearch/StyledProductFormComponent';


const PlatformSelectTable = ({
  small,
  loading,
  options = null,
  selectedOptions = null,
  pageTitle = null,
  updateSelections,
  authPagesConfig: {
    primaryText,
    buttonHighlight,
    selectButtonBg,
  },
}) => {
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (selectedOptions) setSelected(selectedOptions);
  }, [selectedOptions]);

  const handleSelection = (uid) => {
    const prevSelected = [...selected];
    if (prevSelected.includes(uid)) {
      _.pull(prevSelected, uid);
    } else {
      prevSelected.push(uid);
    }

    setSelected(prevSelected);
  };

  const renderContents = () => {
    if (loading || !options) {
      return (
        <NoDataPlaceholder
          noShadow
          noRound
          showRefreshButton={false}
          buttonHighlight={buttonHighlight}
          loading={loading}
          label="Error loading settings data"
          textColor={primaryText}
          loaderColor={buttonHighlight}
        />
      );
    }

    return (
      <Box wrap direction="row">
        {options?.length > 0 && options.map((dt) => (
          <Box key={dt} pad={{ right: '1rem', vertical: '0.5rem' }}>
            <StyledModalSelectButton
              plain
              selected={selected?.includes(dt)}
              textSize="1rem"
              onClick={() => handleSelection(dt)}
              primaryText={primaryText}
              buttonHighlight={buttonHighlight}
              buttonBg={selectButtonBg}
              label={dt}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const saveDisabled = selected?.every((option) => selectedOptions?.includes(option));

  return (
    <Box direction="column" fill="horizontal">
      <TableWrapper
        small={small}
        header={pageTitle}
        textColor={primaryText}
        boxPad={{ horizontal: small ? '1.5rem' : '2rem', top: '1rem', bottom: small ? '1.5rem' : '2rem' }}
        tableControls={(
          <Box direction="row" gap="0.5rem">
            <AppButton
              overrideHover
              disabled={saveDisabled}
              onClick={() => updateSelections(selectedOptions, selected)}
              level="tableControls"
              color="white"
              bgColor={buttonHighlight}
              fontWeight={600}
              label="Save Changes"
            />
          </Box>
        )}
      >
        <Box>
          {renderContents()}
        </Box>
      </TableWrapper>
    </Box>
  );
};

PlatformSelectTable.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      searchPriorityIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageTitle: PropTypes.string,
  updateSelections: PropTypes.func.isRequired,
};

export default PlatformSelectTable;

import QueryString from 'query-string';
import qs from 'qs';
import { serialize } from 'object-to-formdata';
import Case from 'case';

import { logCloudwatchError } from '@Helpers/util';
import { getAbortControllerSignal } from './aborterHelper';


export function formatJsonError(errorMessage) {
  if (typeof errorMessage === 'object') {
    const errorKeys = Object.keys(errorMessage);
    const errorMessages = errorKeys.map((key) => {
      const withKey = (key === 'base' || key === 'error') ? '' : `${Case.title(key)} `;
      if (Array.isArray(errorMessage[key])) {
        return `${withKey}${errorMessage[key].join(', ')}`;
      }

      return `${withKey}${errorMessage[key]}`;
    });

    return errorMessages?.join(', ');
  }

  return errorMessage;
}

async function makeRequest(method, path, body, bearerToken, version, signal, customError = false) {
  const options = {
    method,
    signal,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Key-Inflection': 'camel',
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(`${process.env.API_URL_V2}/${version}/${path}`, options);

  if (!response.ok && !customError) {
    const json = await response.json();
    const useError = json.error || json.errors;
    const formattedMessage = formatJsonError(useError);
    const error = new Error(formattedMessage);
    logCloudwatchError('ERROR', error.message, error.stack);

    throw error;
  }

  return response;
}

async function makeFormRequest(formData, bearerToken, version, path, method = 'POST') {
  const options = {
    method,
    body: formData,
    credentials: 'include',
    headers: { 'Key-Inflection': 'camel' },
  };

  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(`${process.env.API_URL_V2}/${version}/${path}`, options);

  if (!response.ok) {
    const json = await response.json();
    const useError = json.error || json.errors;
    const formattedMessage = formatJsonError(useError);
    const error = new Error(formattedMessage);
    logCloudwatchError('ERROR', error.message, error.stack);

    throw error;
  }

  return response;
}

export async function makeExportRequest(path, bearerToken, version) {
  const options = {
    method: 'POST',
    responseType: 'arraybuffer',
    credentials: 'include',
    headers: { 'Key-Inflection': 'camel' },
  };

  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(`${process.env.API_URL_V2}/${version}/${path}`, options);

  if (!response.ok) {
    const blob = await response.blob();
    const error = new Error(blob.error);
    logCloudwatchError('ERROR', error.message, error.stack);

    throw error;
  }

  return response;
}

async function makeMixedFormRequest(path, formData, bearerToken, version) {
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: { 'Key-Inflection': 'camel' },
  };

  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(`${process.env.API_URL_V2}/${version}/${path}`, options);

  return response;
}

export async function uploadFile(path, file, bearerToken = null, version = 'v1', method = 'POST') {
  const formData = new FormData();
  formData.append('file', file);

  const response = await makeFormRequest(formData, bearerToken, version, path, method);
  return response;
}

export async function put(path, body, bearerToken = null, version = 'v1') {
  return makeRequest('PUT', path, body, bearerToken, version, null);
}

export async function post(path, body, bearerToken = null, version = 'v1') {
  return makeRequest('POST', path, body, bearerToken, version, null);
}

export async function customErrorPost(path, body, bearerToken = null, version = 'v1') {
  return makeRequest('POST', path, body, bearerToken, version, null, true);
}

export async function mixedFormDataPost(path, body, bearerToken = null, version = 'v1') {
  const formData = serialize(body);

  return makeMixedFormRequest(path, formData, bearerToken, version, null);
}

export async function patch(path, body, bearerToken = null, version = 'v1') {
  return makeRequest('PATCH', path, body, bearerToken, version, null);
}

export async function get(path, params = null, bearerToken = null, version = 'v1') {
  const getPath = () => {
    if (params) {
      return `${path}?${QueryString.stringify(params, {
        arrayFormat: 'bracket',
      })}`;
    }

    return path;
  };

  return makeRequest('GET', getPath(), null, bearerToken, version, getAbortControllerSignal());
}

export async function nestedParamsGet(path, args, bearerToken = null, version = 'v1') {
  const params = qs.stringify(args, { encode: false, arrayFormat: 'brackets' });

  return makeRequest('GET', `${path}?${params}`, null, bearerToken, version, getAbortControllerSignal());
}

export async function del(path, body, bearerToken = null, version = 'v1') {
  return makeRequest('DELETE', path, body, bearerToken, version, null);
}

export function extractErrorMessage(jsonErrors) {
  const errorKeys = Object.keys(jsonErrors);
  const errorMessages = errorKeys.map((key) => {
    const withKey = key === 'base' ? '' : `${Case.title(key)} `;
    if (Array.isArray(jsonErrors[key])) {
      return `${withKey}${jsonErrors[key].join(', ')}`;
    }

    return `${withKey}${jsonErrors[key]}`;
  });

  return errorMessages;
}

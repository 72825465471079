import {
  PRODUCT_OVERVIEW_ACCESS_DATA_LOADED,
  PRODUCT_OVERVIEW_ACCESS_DATA_CLEAR,
  PRODUCT_REPORT_ACCESS_DATA_LOADED,
  PRODUCT_REPORT_ACCESS_DATA_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  embedAccessToken: null,
  embedAccessTokenExpiration: null,
  embedCustomerId: null,
  reportAccessToken: null,
  reportAccessTokenExpiration: null,
  reportCustomerId: null,
};

const productEmbed = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_OVERVIEW_ACCESS_DATA_LOADED:
      return {
        ...state,
        embedAccessToken: action.payload.accessData.access_token,
        embedAccessTokenExpiration: action.payload.accessData.expires_in,
        embedCustomerId: action.payload.accessData.customer,
      };

    case PRODUCT_OVERVIEW_ACCESS_DATA_CLEAR:
      return {
        ...state,
        embedAccessToken: null,
        embedAccessTokenExpiration: null,
        embedCustomerId: null,
      };

    case PRODUCT_REPORT_ACCESS_DATA_LOADED:
      return {
        ...state,
        reportAccessToken: action.payload.accessData.access_token,
        reportAccessTokenExpiration: action.payload.accessData.expires_in,
        reportCustomerId: action.payload.accessData.customer,
      };

    case PRODUCT_REPORT_ACCESS_DATA_CLEAR:
      return {
        ...state,
        reportAccessToken: null,
        reportAccessTokenExpiration: null,
        reportCustomerId: null,
      };

    default:
      return state;
  }
};

export default productEmbed;

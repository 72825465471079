import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uniqid from 'uniqid';
import Case from 'case';

import {
  Box, Text, Button,
} from 'grommet';
import { FormNext } from 'grommet-icons';

import {
  Seo, FullHeightBox, AppAnchor, Spinning,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper, AuthBanner } from '@Components/Navigation';
import {
  sendPasswordResetInstructionsRequest,
  authUploadCompanyLogoRequest,
  fetchCustomerSubscriptionRequest,
  clearCustomerSubscriptionRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import { StyledAccountInfoContainer, StyledStatusBadge } from './StyledAccountPage';
import { ProfileLogo } from '../../Navigation/HeaderComponent';


export const Subscriptions = ({
  small,
  loading,
  loaderColor,
  subscriptionData,
  textColor,
}) => {
  if (loading) {
    return (
      <Box direction="column" pad="1rem" width={small ? '100%' : '25%'} align="center" justify="center">
        <Spinning color={loaderColor} size="large" />
      </Box>
    );
  }

  if (!loading && !subscriptionData) return null;

  const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
  const formatDate = (date) => (date ? new Date(date * 1000).toLocaleDateString('en-US', dateOptions) : null);

  const productData = subscriptionData?.productData;
  const subStatus = subscriptionData?.status;
  const cardData = subscriptionData?.cardData;
  const cardDisplay = cardData && `${cardData.brand} ending in ${cardData.last4}`;
  const createdDate = formatDate(subscriptionData?.createdAt);
  const periodStart = formatDate(subscriptionData?.currentPeriodStart);
  const periodEnd = formatDate(subscriptionData?.currentPeriodEnd);
  const canceledDate = formatDate(subscriptionData?.canceledDate);
  const willCancelDate = formatDate(subscriptionData?.willCancelDate);
  const canceledMessage = canceledDate && willCancelDate && `Canceled on ${canceledDate}, valid through ${willCancelDate}`;

  return (
    <Box direction="column" gap="1rem" width={small ? '100%' : '50%'}>
      {canceledMessage && (
        <StyledStatusBadge background="#FCF8EF">
          <Text size={small ? '1rem' : '1.1rem'} color="#AB5741" weight={600}>
            {canceledMessage}
          </Text>
        </StyledStatusBadge>
      )}
      <Box direction="row" align="center" gap="1rem">
        <Text size="1.2rem" color={textColor} weight={600}>{productData.name}</Text>
        <StyledStatusBadge background={subStatus === 'active' ? '#EDFDF5' : '#FCF8EF'}>
          <Text size="1.1rem" color={subStatus === 'active' ? '#22684B' : '#AB5741'} weight={600}>
            {Case.capital(subscriptionData?.status)}
          </Text>
        </StyledStatusBadge>
      </Box>
      <Box direction="column" gap="0.75rem">
        <Box direction="row" gap="0.5rem" align="center">
          <FormNext size="1rem" color="#038BFC" />
          <Text size="1rem" color={textColor} weight={500}>
            {`Monitoring up to ${productData?.metadata?.searchLimit} of your videos`}
          </Text>
        </Box>
        <Box direction="row" gap="0.5rem" align="center">
          <FormNext size="1rem" color="#038BFC" />
          <Text size="1rem" color={textColor} weight={500}>
            Unlimited takedown requests
          </Text>
        </Box>
        <Box direction="column" gap="0.75rem" pad={{ top: '0.5rem' }}>
          <Text size="1rem" color={textColor} weight={400}>
            Customer since:
            {' '}
            {createdDate}
          </Text>
          <Text size="1rem" color={textColor} weight={400}>
            Current period:
            {' '}
            {periodStart}
            {' '}
            to
            {' '}
            {periodEnd}
          </Text>
          {cardData && (
            <Text size="1rem" color={textColor} weight={400}>
              Payment method:
              {' '}
              {cardDisplay}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Subscriptions.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loaderColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  subscriptionData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    productData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        searchLimit: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        verticalUid: PropTypes.string.isRequired,
      }).isRequired,
      prices: PropTypes.arrayOf(PropTypes.shape({
        priceId: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        unitAmount: PropTypes.number.isRequired,
        metadata: PropTypes.shape({
          order: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          displayPlatforms: PropTypes.string.isRequired,
          platforms: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    cardData: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number.isRequired,
      expYear: PropTypes.number.isRequired,
    }),
    createdAt: PropTypes.number.isRequired,
    currentPeriodEnd: PropTypes.number.isRequired,
    currentPeriodStart: PropTypes.number.isRequired,
    canceledDate: PropTypes.number,
    willCancelDate: PropTypes.number,
  }),
};

export const SectionContainer = ({
  small,
  sectionTitle,
  textColor,
  children,
  customWidth = null,
  ...rest
}) => {
  const borderProps = { size: '1px', style: 'solid', side: 'bottom' };
  let width = small ? '55%' : '25%';
  if (customWidth) width = customWidth;

  return (
    <Box direction="column" gap="1.5rem" background="white" round="10px" pad="1.5rem" {...rest}>
      <Box width={width} border={{ color: '#DDE2E4', ...borderProps }} style={{ minWidth: '15rem' }}>
        <Box
          flex={false}
          border={{ color: '#252C32', ...borderProps }}
          justify="end"
          pad={{ bottom: '0.5rem' }}
          width="fit-content"
        >
          <Text color={textColor} weight={600} size="1.25rem">
            {sectionTitle}
          </Text>
        </Box>
      </Box>
      <Box direction="column" gap="2rem">
        {children}
      </Box>
    </Box>
  );
};

SectionContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  customWidth: PropTypes.string,
};

const PageWrapper = ({
  small,
  location,
  authPagesConfig,
  unconfiguredCompany = false,
  customReports = null,
  cyclopsConfig = null,
  children,
}) => {
  if (unconfiguredCompany) {
    return (
      <FullHeightBox background={authPagesConfig.pageBg} pad={{ top: small ? '5.2rem' : '3rem' }}>
        <AuthBanner
          navCollapsed
          adjustLeftPad
          small={small}
          image={authPagesConfig.bannerBg}
          textColor="white"
          title="Account information"
          subTitle="Plan and profile settings"
          bannerChildren={null}
        />
        {children}
      </FullHeightBox>
    );
  }

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Account information',
        subTitle: 'Plan and profile settings',
        textColor: 'white',
      }}
    >
      {children}
    </AuthNavWrapper>
  );
};

PageWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }).isRequired,
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  unconfiguredCompany: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const AccountPage = ({
  small,
  location,
  companyEmail,
  companyLogo,
  companyId,
  sendResetInstructions,
  uploadCompanyLogo,
  fetchSubscription,
  clearSubscription,
  loading,
  subscriptionData = null,
  authPagesConfig = null,
  unconfiguredCompany = false,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const logoUploadRef = React.useRef(null);

  React.useEffect(() => {
    fetchSubscription(companyId);

    return () => clearSubscription();
  }, []);

  const {
    primaryText, highlightText, buttonHighlight, accountPlaceholder,
  } = authPagesConfig;

  const replaceWithDots = (str) => (str.replace(/[^ ]/g, '•'));
  const customerMessage = unconfiguredCompany
    ? 'Account features have not yet been activated. To activate, please contact Redflag at'
    : 'If you have questions or feedback, please contact Redflag at';

  return (
    <PageWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      unconfiguredCompany={unconfiguredCompany}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex direction="column" background="#F4F4F4" pad={small ? '2rem' : '3rem'}>
        <input
          hidden
          key={uniqid()}
          type="file"
          accept="image/*"
          onChange={(e) => uploadCompanyLogo(e.currentTarget.files[0])}
          ref={logoUploadRef}
        />
        <SectionContainer small={small} sectionTitle="Plan" textColor={primaryText} margin={{ bottom: '2rem' }}>
          <Box direction="column" gap={(!loading && subscriptionData) ? '1.5rem' : '0'}>
            <Subscriptions
              small={small}
              loading={loading}
              loaderColor={buttonHighlight}
              subscriptionData={subscriptionData}
              textColor={primaryText}
            />
            {subscriptionData && (
              <AppAnchor path={paths.manageSubscription.replace(':id', subscriptionData?.verticalUid)} color={highlightText}>
                Manage subscription & payment method
              </AppAnchor>
            )}
            <Text color={primaryText} weight={400} size="1rem">
              {customerMessage}
              {' '}
              <AppAnchor href="mailto:contact@redflagai.co" target="_blank" color={highlightText}>
                contact@redflagai.co
              </AppAnchor>
            </Text>
          </Box>
        </SectionContainer>
        <SectionContainer small={small} sectionTitle="Profile" textColor={primaryText}>
          <Box direction="column" gap="0.85rem">
            <ProfileLogo
              small={small}
              logoUrl={companyLogo}
              placeholder={accountPlaceholder}
              outlineColor={buttonHighlight}
              customSize={small ? '7rem' : '6rem'}
            />
            <Button plain onClick={() => logoUploadRef.current.click()} style={{ maxWidth: 'fit-content' }}>
              <Text weight={500} size="0.9rem" color={highlightText}>Upload photo</Text>
            </Button>
          </Box>
          <Box direction="column" gap="1.35rem">
            <Text size="1rem">This is your registered email, you may reset your password by clicking the link below and following the instructions you&apos;ll recieve by email</Text>
            <StyledAccountInfoContainer focusHighlight={highlightText} width={{ width: small ? '100%' : '40%' }}>
              <Text size="1rem" color={primaryText} weight={500}>
                {companyEmail}
              </Text>
            </StyledAccountInfoContainer>
            <Box>
              <StyledAccountInfoContainer focusHighlight={highlightText} width={{ width: small ? '100%' : '40%' }}>
                <Text size="1rem" color={primaryText} weight={500}>
                  {replaceWithDots(uniqid())}
                </Text>
              </StyledAccountInfoContainer>
              <Box pad={{ top: '0.5rem' }}>
                <Button plain onClick={() => sendResetInstructions(companyEmail, paths.account)} style={{ maxWidth: 'fit-content' }}>
                  <Text weight={500} size="0.9rem" color={highlightText}>Reset password</Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      </Box>
    </PageWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyEmail: state.auth.email,
    companyLogo: state.auth.companyLogo,
    companyId: state.auth.companyId,
    loading: state.fetchLoader.dataLoading,
    subscriptionData: state.payment.subscriptionData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    sendResetInstructions: sendPasswordResetInstructionsRequest,
    uploadCompanyLogo: authUploadCompanyLogoRequest,
    fetchSubscription: fetchCustomerSubscriptionRequest,
    clearSubscription: clearCustomerSubscriptionRequest,
  }, dispatch);
}

AccountPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    accountPlaceholder: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  unconfiguredCompany: PropTypes.bool,
  companyEmail: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  companyLogo: PropTypes.string,
  sendResetInstructions: PropTypes.func.isRequired,
  uploadCompanyLogo: PropTypes.func.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  clearSubscription: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subscriptionData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    productData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        searchLimit: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        verticalUid: PropTypes.string.isRequired,
      }).isRequired,
      prices: PropTypes.arrayOf(PropTypes.shape({
        priceId: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        unitAmount: PropTypes.number.isRequired,
        metadata: PropTypes.shape({
          order: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          displayPlatforms: PropTypes.string.isRequired,
          platforms: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    cardData: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number.isRequired,
      expYear: PropTypes.number.isRequired,
    }),
    createdAt: PropTypes.number.isRequired,
    currentPeriodEnd: PropTypes.number.isRequired,
    currentPeriodStart: PropTypes.number.isRequired,
    canceledDate: PropTypes.number,
    willCancelDate: PropTypes.number,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(AccountPage));

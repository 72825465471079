import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'grommet';

import { NoDataPlaceholder } from '../SharedComponents';


const StyledFrameContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: fit-content;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const DomoEmbed = ({
  loading,
  textColor,
  highlightColor,
  embedId = null,
  embedToken = null,
  embedExpired = false,
  embedLinkInvalid = false,
  noAuth = false,
}) => {
  const [frameLoaded, setFrameLoaded] = React.useState(false);
  const [frameWidth, setFrameWidth] = React.useState(0);

  const handleResize = () => {
    const containerWidth = document.querySelector('.iframe-container')?.offsetWidth;
    setFrameWidth(containerWidth);
  };

  React.useEffect(() => {
    if (embedToken) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [embedToken]);

  const handleFrameLoad = (event) => {
    const iframe = event.target;

    if (iframe?.contentDocument?.getElementById('embed-form')) {
      const form = iframe.contentDocument.getElementById('embed-form');
      form.submit();
    } else {
      setFrameLoaded(true);
    }
  };

  let noLoadMessage = embedExpired ? 'This share link has expired' : 'Unable to load report';

  if (embedLinkInvalid && noAuth) {
    noLoadMessage = 'Invalid share link';
  }

  let loadingCondition = loading || !embedToken || !frameLoaded;

  if (noAuth) {
    loadingCondition = loading
      || (!embedToken && !embedLinkInvalid && !embedExpired)
      || (!frameLoaded && !embedLinkInvalid && !embedExpired);
  }

  return (
    <Box flex>
      {(loading || !embedToken || !frameLoaded || !embedId || embedExpired || embedLinkInvalid) && (
        <NoDataPlaceholder
          noRound
          label={noLoadMessage}
          textColor={textColor}
          backgroundColor="white"
          loading={loadingCondition}
          loaderColor={highlightColor}
        />
      )}
      {embedId && embedToken && (
        <StyledFrameContainer
          flex
          className="iframe-container"
          align="center"
          justify="center"
          style={{ visibility: frameLoaded ? 'visible' : 'hidden' }}
        >
          <iframe
            id="domoEmbed"
            title="Domo Piracy Overview"
            width={frameWidth}
            allow="fullscreen"
            srcDoc={`
              <!DOCTYPE html>
              <html>
                <body>
                  <form
                    id="embed-form"
                    action="${process.env.DOMO_EMBED_BASE_URL}${embedId}"
                    method="post"
                  >
                    <input type="hidden" name="embedToken" value=${embedToken} />
                  </form>
                </body>
              </html>
            `}
            onLoad={handleFrameLoad}
          />
        </StyledFrameContainer>
      )}
    </Box>
  );
};

DomoEmbed.propTypes = {
  loading: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  embedId: PropTypes.string,
  embedToken: PropTypes.string,
  embedExpired: PropTypes.bool,
  embedLinkInvalid: PropTypes.bool,
  noAuth: PropTypes.bool,
};

export default DomoEmbed;

import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import ReactTooltip from 'react-tooltip';

import { ActionButton } from '@Components/Control';


export const ShareButton = ({
  small,
  textColor,
  renderKey,
  buttonLabel,
  buttonBg = 'white',
  copyMessage,
  copyValue,
  iconPath = null,
  icon = null,
}) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <Box>
      <ActionButton
        data-for={`${renderKey}-tooltip`}
        data-tip={copied ? 'Copied to clipboard!' : copyMessage}
        key={copied ? `${renderKey}-copied` : renderKey}
        small={small}
        label={buttonLabel}
        iconPath={iconPath}
        icon={icon}
        textColor={textColor}
        style={{ backgroundColor: buttonBg }}
        actionHandler={() => {
          navigator.clipboard.writeText(copyValue);
          setCopied(true);
        }}
      />
      <ReactTooltip
        id={`${renderKey}-tooltip`}
        key={copied ? `${renderKey}-tooltip-copied` : `${renderKey}-tooltip`}
        data-offset="{'left': 10}"
        place="left"
        effect="solid"
        tooltip
      />
    </Box>
  );
};

ShareButton.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  renderKey: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  copyMessage: PropTypes.string.isRequired,
  copyValue: PropTypes.string.isRequired,
  iconPath: PropTypes.string,
  icon: PropTypes.element,
  buttonBg: PropTypes.string,
};

export default ShareButton;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box, Text,
} from 'grommet';
import { FormPrevious } from 'grommet-icons';

import {
  AppAnchor, AppButton, SubmissionLoader,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  PaymentFormPartial,
} from '@Components/Partial/Payment';
import { ProductFormSingleTextInput, ProductFormCheckBoxInput } from '@Components/Partial/Product/NewSearch';
import {
  fetchCustomerSubscriptionRequest,
  clearCustomerSubscriptionRequest,
  initiateUpdateSubscriptionRequest,
  initiateUpdatePaymentMethodRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import { SectionContainer } from './AccountPage';
import { StyledStatusBadge, StyledText } from './StyledAccountPage';


const ManageSubscriptionPage = ({
  small,
  location,
  companyId,
  fetchSubscription,
  clearSubscription,
  updateSubscription,
  updatePaymentMethod,
  submitLoading,
  subscriptionData = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const { id: currentVertical } = useParams();
  const navigate = useNavigate();

  if (!currentVertical) navigate(paths.account);

  /* eslint-disable camelcase */
  const [cancelRequestBody, setCancelRequestBody] = React.useState({
    sub_uid: null,
    cancel_sub: true,
    cancel_reason: '',
  });
  const [acceptCancellation, setAcceptCancellation] = React.useState(false);

  React.useEffect(() => {
    fetchSubscription(companyId);

    return () => {
      clearSubscription();
    };
  }, []);

  React.useEffect(() => {
    if (subscriptionData) {
      setCancelRequestBody((prev) => ({
        ...prev,
        sub_uid: subscriptionData.uid,
      }));
    }
  }, [subscriptionData]);

  const {
    primaryText, buttonHighlight, focusHighlight,
  } = authPagesConfig;

  const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
  const formatDate = (date) => (date ? new Date(date * 1000).toLocaleDateString('en-US', dateOptions) : null);

  const activeSubTier = subscriptionData?.tierUid;
  const cardData = subscriptionData?.cardData;
  const cardDisplay = cardData && `${cardData.brand} ending in ${cardData.last4}, expires ${cardData.expMonth}/${cardData.expYear}`;
  const periodEnd = subscriptionData && formatDate(subscriptionData.currentPeriodEnd);
  const canceledDate = formatDate(subscriptionData?.canceledDate);
  const willCancelDate = formatDate(subscriptionData?.willCancelDate);
  const canceledMessage = canceledDate && willCancelDate && `Canceled on ${canceledDate}, valid through ${willCancelDate}`;
  const isCanceled = canceledMessage ?? false;

  let cancelMessage = 'I wish to end my subscription at the end of the current pay period.';
  let buttonLabel = 'Cancel subscription';

  if (isCanceled) {
    cancelMessage = 'I wish to reactivate my subscription.';
    buttonLabel = 'Reactivate subscription';
  }

  const handleCancelRequest = () => {
    if (isCanceled) {
      const restoreBody = {
        tier: activeSubTier,
        sub_uid: subscriptionData?.uid,
        cancel_sub: false,
      };

      updateSubscription(restoreBody, paths.account, true);
    } else {
      updateSubscription(cancelRequestBody, paths.account);
    }
  };

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Account information',
        subTitle: 'Manage subscription',
        textColor: 'white',
      }}
    >
      <Box flex direction="column" background="#F4F4F4" pad={small ? '2rem' : '3rem'}>
        {submitLoading && (
          <SubmissionLoader
            textColor={primaryText}
            loaderColor={buttonHighlight}
            message="Updating subscription..."
            contentWidth="18rem"
          />
        )}
        <Box pad={{ bottom: '2rem' }}>
          <AppAnchor path={paths.account}>
            <Box direction="row" align="center" gap="0.15rem">
              <FormPrevious size="0.85rem" color="#038BFC" />
              <Text size="0.85rem" color="#038BFC">Back to account</Text>
            </Box>
          </AppAnchor>
        </Box>
        <SectionContainer
          small={small}
          sectionTitle="Update payment method"
          customWidth={small ? '60%' : '30%'}
          textColor={primaryText}
          margin={{ bottom: '2rem' }}
        >
          <Box direction="column" gap="1rem">
            <Box direction="column" gap="1rem" width={small ? '100%' : '90%'}>
              <Text size="1rem" color={primaryText} weight={500}>
                Current payment method:
                {' '}
                {cardDisplay}
              </Text>
              <StyledText size="1rem" color={primaryText}>
                Update your payment method by entering your new card details.
                This card will be charged at the end of your current pay period on
                {' '}
                {periodEnd}
                .
              </StyledText>
            </Box>
            <Box width={small ? '100%' : '60%'} pad={{ top: '0.5rem' }}>
              <PaymentFormPartial
                updateOnly
                checkOutLoading={false}
                checkout={updatePaymentMethod}
                redirectPath={paths.account}
                submitMessage="Update payment method"
                buttonHighlight={buttonHighlight}
                submitDisabled={false}
              />
            </Box>
          </Box>
        </SectionContainer>
        <SectionContainer
          small={small}
          sectionTitle="Cancel subscription"
          customWidth={small ? '60%' : '30%'}
          textColor={primaryText}
          margin={{ bottom: '2rem' }}
        >
          <Box direction="column" gap="1rem">
            <Box direction="column" gap="1rem" width={small ? '100%' : '90%'}>
              {(canceledMessage && isCanceled) ? (
                <Box direction="column" gap="1rem">
                  <StyledStatusBadge background="#FCF8EF">
                    <Text size={small ? '1rem' : '1.1rem'} color="#AB5741" weight={600}>
                      {canceledMessage}
                    </Text>
                  </StyledStatusBadge>
                  <StyledText size="1rem" color={primaryText}>
                    You can reactivate your subscription any time prior
                    to the end of your current pay period on
                    {' '}
                    {periodEnd}
                    . You will be charged normally at that time and
                    retain all product access.
                  </StyledText>
                </Box>
              ) : (
                <StyledText size="1rem" color={primaryText}>
                  We&apos;re sorry to see you go! Please leave a comment to let us
                  know why you&apos;re cancelling your subscription.
                  You will continue to have full product access until the
                  end of your current pay period on
                  {' '}
                  {periodEnd}
                  .
                </StyledText>
              )}
            </Box>
            <Box direction="column" gap="1rem" width={small ? '100%' : '60%'}>
              {!isCanceled && (
                <ProductFormSingleTextInput
                  placeholder="Enter your reason for cancellation (optional)"
                  value={cancelRequestBody.cancel_reason}
                  focusHighlight={focusHighlight}
                  handleFormValues={(val) => setCancelRequestBody((prev) => ({
                    ...prev,
                    cancel_reason: val,
                  }))}
                />
              )}
              <Box direction="row" gap="1rem" align="center" pad={{ bottom: '1rem' }}>
                <Box width="fit-content">
                  <ProductFormCheckBoxInput
                    small={small}
                    key="accept-checkbox"
                    bgColor="white"
                    borderColor="#D0D0D0"
                    checkmarkColor={primaryText}
                    name="accept-checkbox"
                    focusHighlight={focusHighlight}
                    value={acceptCancellation}
                    handleFormValues={(val) => setAcceptCancellation(val)}
                  />
                </Box>
                <Text size="1rem" color={primaryText}>
                  {cancelMessage}
                </Text>
              </Box>
              <AppButton
                overrideHover
                onClick={() => handleCancelRequest()}
                level="authSubmit"
                disabled={!acceptCancellation || submitLoading}
                bgColor="#EC2C2C"
                color="white"
                label={buttonLabel}
                alignSelf="center"
              />
            </Box>
          </Box>
        </SectionContainer>
      </Box>
    </AuthNavWrapper>
  );
  /* eslint-enable camelcase */
};

function mapStateToProps(state) {
  return {
    companyId: state.auth.companyId,
    submitLoading: state.fetchLoader.submissionLoading,
    subscriptionData: state.payment.subscriptionData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchSubscription: fetchCustomerSubscriptionRequest,
    clearSubscription: clearCustomerSubscriptionRequest,
    updateSubscription: initiateUpdateSubscriptionRequest,
    updatePaymentMethod: initiateUpdatePaymentMethodRequest,
  }, dispatch);
}

ManageSubscriptionPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    accountPlaceholder: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyId: PropTypes.number.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  clearSubscription: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  subscriptionData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    productData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        searchLimit: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        verticalUid: PropTypes.string.isRequired,
      }).isRequired,
      prices: PropTypes.arrayOf(PropTypes.shape({
        priceId: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        unitAmount: PropTypes.number.isRequired,
        metadata: PropTypes.shape({
          order: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          displayPlatforms: PropTypes.string.isRequired,
          platforms: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    cardData: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number.isRequired,
      expYear: PropTypes.number.isRequired,
    }),
    createdAt: PropTypes.number.isRequired,
    currentPeriodEnd: PropTypes.number.isRequired,
    currentPeriodStart: PropTypes.number.isRequired,
    canceledDate: PropTypes.number,
    willCancelDate: PropTypes.number,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(ManageSubscriptionPage),
);

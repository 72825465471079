import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  Box, Text, Anchor,
} from 'grommet';

import {
  AppButton, SubmissionLoader,
} from '@Components/Control';
import { TermsOfUseModal } from '@Components/Modal';
import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import {
  PaymentFormPartial,
  ProductSelectorPartial,
  SubscriberFormPartial,
} from '@Components/Partial/Payment';
import { ProductFormCheckBoxInput } from '@Components/Partial/Product/NewSearch';
import { CurrentDateContext } from '@Components/Context';
import { formatStripePrice } from '@Helpers/util';
import { paths } from '@Components/configs';
import {
  initiateCreateSubscriptionRequest,
  fetchVerticalTiersRequest,
} from '@Actions';


const StyledText = styled(Text)`
  line-height: 1.5;
  font-size: 1rem;
`;

const SignupContent = ({
  small,
  submitLoading,
  activeTier,
  tierPrices,
  channelLink,
  createSignupRequest,
  defaultCompanyName,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
    selectButtonBg,
  },
}) => {
  /* eslint-disable camelcase */
  const [requestBody, setRequestBody] = React.useState({
    company_name: defaultCompanyName,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    vertical: activeTier?.metadata?.verticalUid,
    allow_channel: channelLink,
    tier: activeTier?.metadata?.uid,
    stripe_price_id: '',
    terms_agreed: false,
    platforms: [],
  });
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [selectedPrice, setSelectedPrice] = React.useState(null);
  const [showForm, setShowForm] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);

  const { today } = React.useContext(CurrentDateContext);
  const currentYear = today.getUTCFullYear();

  React.useEffect(() => {
    if (selectedPrice) {
      const usePlatforms = selectedPrice.metadata.platforms.split(', ');

      setRequestBody({
        ...requestBody,
        stripe_price_id: selectedPrice.priceId,
        platforms: usePlatforms,
      });
    }
  }, [selectedPrice]);

  const submitDisabled = !requestBody.company_name || !requestBody.first_name
    || !requestBody.last_name || !requestBody.email || !requestBody.tier
    || !requestBody.stripe_price_id || !requestBody.terms_agreed
    || !requestBody.password || !confirmPassword
    || requestBody.password !== confirmPassword;
  const continueDisabled = !selectedPrice;

  const renderContent = () => {
    if (showForm) {
      return (
        <Box direction="column">
          <StyledSubmissionField required name="companyName" htmlFor="companyName" type="text">
            <StyledSubmissionInput
              plain
              name="companyName"
              type="text"
              placeholder="Company name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, company_name: e.target.value })}
              value={requestBody.company_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="firstName" htmlFor="firstName" type="text">
            <StyledSubmissionInput
              plain
              name="firstName"
              type="text"
              placeholder="First name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, first_name: e.target.value })}
              value={requestBody.first_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="lastName" htmlFor="lastName" type="text">
            <StyledSubmissionInput
              plain
              name="lastName"
              type="text"
              placeholder="Last name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, last_name: e.target.value })}
              value={requestBody.last_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="userEmail" htmlFor="userEmail" type="email">
            <StyledSubmissionInput
              plain
              name="userEmail"
              type="email"
              placeholder="Email"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, email: e.target.value })}
              value={requestBody.email}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="userPassword" htmlFor="userPassword" type="password">
            <StyledSubmissionInput
              plain
              name="userPassword"
              type="password"
              placeholder="Password"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, password: e.target.value })}
              value={requestBody.password}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="confirmPassword" htmlFor="confirmPassword" type="password">
            <StyledSubmissionInput
              plain
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
              highlight={highlight}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </StyledSubmissionField>
          <Box direction="row" gap="1rem" align="center" pad={{ bottom: '1rem' }}>
            <Box width="fit-content">
              <ProductFormCheckBoxInput
                small={small}
                key="terms-checkbox"
                bgColor="white"
                borderColor="#D0D0D0"
                checkmarkColor={textColor}
                name="terms-checkbox"
                focusHighlight={highlight}
                value={requestBody.terms_agreed}
                handleFormValues={(val) => setRequestBody({ ...requestBody, terms_agreed: val })}
              />
            </Box>
            <Text size="1rem" color={textColor}>
              I have read and agree to the
              {' '}
              <Text
                size="1rem"
                color={highlight}
                onClick={() => setShowTermsModal(true)}
                style={{ cursor: 'pointer' }}
              >
                Terms of Use
              </Text>
            </Text>
          </Box>
          <PaymentFormPartial
            requestBody={requestBody}
            checkOutLoading={false}
            checkout={createSignupRequest}
            submitMessage="Sign up"
            redirectPath={paths.login}
            buttonHighlight={highlight}
            submitDisabled={submitDisabled || submitLoading}
          />
          <Box pad={{ top: '1rem' }}>
            <Text size="0.8rem" color="#9C9C9C" textAlign="start">
              By continuing, you authorize Redflag AI Inc. to charge
              this card for the amount of
              {' '}
              {selectedPrice && formatStripePrice(selectedPrice.unitAmount, selectedPrice.currency)}
              {' '}
              monthly. You may cancel your subscription at any time,
              and you will retain product access until the end of your
              current billing period.
            </Text>
          </Box>
          {showTermsModal && (
            <TermsOfUseModal
              toggle={() => setShowTermsModal(!showTermsModal)}
              isSmall={small}
              currentYear={currentYear}
              background="white"
              textColor={textColor}
            />
          )}
        </Box>
      );
    }

    return (
      <Box direction="column" gap="1rem" width={small ? '100%' : '30rem'}>
        <StyledText size="1rem" color={textColor} textAlign="start">
          We&apos;re ready to start detecting any infringements of your content
          and getting them taken down!
        </StyledText>
        <ProductSelectorPartial
          activeTier={activeTier}
          tierPrices={tierPrices}
          setSelectedPrice={setSelectedPrice}
          selectedPrice={selectedPrice}
          highlight={highlight}
          selectButtonBg={selectButtonBg}
          textColor={textColor}
        />
        <AppButton
          overrideHover
          onClick={() => setShowForm(true)}
          level="authSubmit"
          disabled={continueDisabled}
          bgColor={highlight}
          color="white"
          label="Continue to details"
          alignSelf="center"
        />
        <Anchor href="https://www.redflagai.co/contact">
          <Box align="center" justify="center" width="100%">
            <Text size="0.85rem" color="#038BFC" textAlign="center">
              Interested in a higher coverage plan? Book a demo with our team
            </Text>
          </Box>
        </Anchor>
      </Box>
    );
  };

  return (
    <Box>
      {submitLoading && (
        <SubmissionLoader
          textColor={textColor}
          loaderColor={highlight}
          message="Creating subscription..."
          contentWidth="18rem"
        />
      )}
      <SubmissionLayout
        withBackButton={showForm}
        backButtonClick={() => setShowForm(false)}
        seoConfig={null}
        title="Sign up"
        bgColor={bgColor}
        bgImage={bgImage}
        textColor={textColor}
        small={small}
        customWidth={showForm ? null : 'fit-content'}
        bottomPad={showForm ? '0' : '3rem'}
      >
        <Box direction="column">
          {renderContent()}
        </Box>
      </SubmissionLayout>
    </Box>
  );
  /* eslint-enable camelcase */
};

SignupContent.propTypes = {
  small: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  createSignupRequest: PropTypes.func.isRequired,
  activeTier: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      searchLimit: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  tierPrices: PropTypes.arrayOf(PropTypes.shape({
    priceId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    unitAmount: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      order: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
      platforms: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  channelLink: PropTypes.string.isRequired,
  defaultCompanyName: PropTypes.string.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
  }).isRequired,
};

const SignupPage = ({
  small,
  loading,
  submitLoading,
  createSignupRequest,
  fetchVerticalTiers,
  config,
  verticalTiers = null,
}) => {
  const [subscriberDetailsConfirmed, setSubscriberDetailsConfirmed] = React.useState(false);
  const [channelLink, setChannelLink] = React.useState('');

  if (!process.env.ENABLE_SIGNUP) {
    return <Navigate to={paths.root} />;
  }

  if (verticalTiers && subscriberDetailsConfirmed && channelLink) {
    return (
      <SignupContent
        small={small}
        submitLoading={submitLoading}
        activeTier={verticalTiers?.tier}
        tierPrices={verticalTiers?.prices}
        channelLink={channelLink}
        defaultCompanyName={verticalTiers?.subscriberData?.channelTitle}
        createSignupRequest={createSignupRequest}
        config={config}
      />
    );
  }

  return (
    <SubscriberFormPartial
      small={small}
      bgColor={config.bgColor}
      bgImage={config.bgImage}
      textColor={config.textColor}
      buttonHighlight={config.highlight}
      fetchVerticalTiers={fetchVerticalTiers}
      setChannelLink={setChannelLink}
      channelLink={channelLink}
      channelDataLoading={loading}
      confirmSubscriberDetails={() => setSubscriberDetailsConfirmed(true)}
      subscriberData={verticalTiers?.subscriberData}
    />
  );
};

function mapStateToProps(state) {
  return {
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    verticalTiers: state.payment.verticalTiers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createSignupRequest: initiateCreateSubscriptionRequest,
    fetchVerticalTiers: fetchVerticalTiersRequest,
  }, dispatch);
}

SignupPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  createSignupRequest: PropTypes.func.isRequired,
  fetchVerticalTiers: PropTypes.func.isRequired,
  verticalTiers: PropTypes.shape({
    tier: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        searchLimit: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        verticalUid: PropTypes.string.isRequired,
      }).isRequired,
    }),
    prices: PropTypes.arrayOf(PropTypes.shape({
      priceId: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      unitAmount: PropTypes.number.isRequired,
      metadata: PropTypes.shape({
        order: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        displayPlatforms: PropTypes.string.isRequired,
        platforms: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    })),
    subscriberData: PropTypes.shape({
      platform: PropTypes.string.isRequired,
      channelId: PropTypes.string.isRequired,
      channelTitle: PropTypes.string.isRequired,
      channelDescription: PropTypes.string.isRequired,
      channelThumbnail: PropTypes.string,
      channelSubscriberCount: PropTypes.string,
      uploadsPlaylistId: PropTypes.string,
      subscriberCountHidden: PropTypes.bool,
      channelViewCount: PropTypes.string,
      channelVideoCount: PropTypes.string,
    }),
  }),
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import { AppButton } from '@Components/Control';

import { createInvitedUserRequest } from '@Actions';

import { paths } from '@Components/configs';

const InvitedUserContent = ({
  small,
  createInvitedUser,
  inviteToken,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
  },
}) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    /* eslint-disable camelcase */
    const requestBody = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        token: inviteToken,
      },
    };
    /* eslint-enable camelcase */

    createInvitedUser(requestBody, paths.login);
  };

  const submitDisabled = !firstName || !lastName || !email || !password
    || !confirmPassword || password !== confirmPassword;

  return (
    <SubmissionLayout
      seoConfig={null}
      title="Create account"
      bgColor={bgColor}
      bgImage={bgImage}
      textColor={textColor}
      small={small}
    >
      <StyledSubmissionField required name="firstName" htmlFor="firstName" type="text">
        <StyledSubmissionInput
          plain
          name="firstName"
          type="text"
          placeholder="First name"
          highlight={highlight}
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
      </StyledSubmissionField>
      <StyledSubmissionField required name="lastName" htmlFor="lastName" type="text">
        <StyledSubmissionInput
          plain
          name="lastName"
          type="text"
          placeholder="Last name"
          highlight={highlight}
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
      </StyledSubmissionField>
      <StyledSubmissionField required name="email" htmlFor="email" type="email">
        <StyledSubmissionInput
          plain
          name="email"
          type="email"
          placeholder="Email"
          highlight={highlight}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </StyledSubmissionField>
      <StyledSubmissionField required name="password" htmlFor="password" type="password">
        <StyledSubmissionInput
          plain
          name="password"
          type="password"
          placeholder="Create a password"
          highlight={highlight}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </StyledSubmissionField>
      <StyledSubmissionField required name="confirmPassword" htmlFor="password" type="password">
        <StyledSubmissionInput
          plain
          name="confirmPassword"
          type="password"
          placeholder="Confirm password"
          highlight={highlight}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
        />
      </StyledSubmissionField>
      <AppButton
        overrideHover
        level="authSubmit"
        onClick={(e) => handleSubmit(e)}
        disabled={submitDisabled}
        bgColor={highlight}
        color="white"
        label="Submit"
        fontWeight={600}
        alignSelf="center"
      />
    </SubmissionLayout>
  );
};

InvitedUserContent.propTypes = {
  small: PropTypes.bool.isRequired,
  inviteToken: PropTypes.string.isRequired,
  createInvitedUser: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
};

const InvitedPage = ({
  small, createInvitedUser, config,
}) => {
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('token');

  if (inviteToken) {
    return (
      <InvitedUserContent
        small={small}
        inviteToken={inviteToken}
        createInvitedUser={createInvitedUser}
        config={config}
      />
    );
  }

  return <Navigate to={paths.root} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createInvitedUser: createInvitedUserRequest,
  }, dispatch);
}

InvitedPage.propTypes = {
  small: PropTypes.bool.isRequired,
  createInvitedUser: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(InvitedPage);

import {
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_REFRESH,
  AUTH_ACCOUNT_LOADED,
  AUTH_ACCOUNT_CLEAR,
  AUTH_USERS_LOADED,
  AUTH_USERS_CLEAR,
  AUTH_INVITE_TOKEN_LOADED,
  AUTH_INVITE_TOKEN_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  email: null,
  firstName: null,
  lastName: null,
  companyName: null,
  companyId: null,
  companyLogo: null,
  companyFeatures: null,
  exportable: false,
  withAccount: false,
  accountUsers: null,
  inviteToken: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOG_IN:
    case AUTH_REFRESH: {
      return {
        ...state,
        isAuthenticated: true,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        companyName: action.payload.companyName,
        companyId: action.payload.companyId,
        companyLogo: action.payload.companyLogo,
        companyFeatures: action.payload.companyFeatures,
        token: action.payload.token,
        exportable: action.payload.exportable,
        withAccount: action.payload.withAccount,
        lastSignInAt: action.payload.lastSignInAt,
      };
    }

    case AUTH_LOG_OUT:
      return initialState;

    case AUTH_ACCOUNT_LOADED:
      return { ...state, account: action.payload.account };

    case AUTH_ACCOUNT_CLEAR:
      return { ...state, account: null };

    case AUTH_USERS_LOADED:
      return { ...state, accountUsers: action.payload.users };

    case AUTH_USERS_CLEAR:
      return { ...state, accountUsers: null };

    case AUTH_INVITE_TOKEN_LOADED:
      return { ...state, inviteToken: action.payload.tokenData };

    case AUTH_INVITE_TOKEN_CLEAR:
      return { ...state, inviteToken: null };

    default:
      return state;
  }
};

export default auth;

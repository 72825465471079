import {
  NOTICE_LOADED,
  NOTICE_CLEAR,
  CUSTOM_NOTICE_LOADED,
  CUSTOM_NOTICE_CLEAR,
  TOUR_STARTED,
  TOUR_ENDED,
} from '../actions/actionTypes';

const initialState = {
  message: null,
  type: null,
  customMessage: null,
  showTour: false,
};

const notice = (state = initialState, action) => {
  switch (action.type) {
    case NOTICE_LOADED:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type || 'warning',
      };

    case NOTICE_CLEAR:
      return {
        ...state,
        message: null,
        type: null,
      };

    case CUSTOM_NOTICE_LOADED:
      return {
        ...state,
        customMessage: action.payload.message,
      };

    case CUSTOM_NOTICE_CLEAR:
      return {
        ...state,
        customMessage: null,
      };

    case TOUR_STARTED:
      return {
        ...state,
        showTour: true,
      };

    case TOUR_ENDED:
      return {
        ...state,
        showTour: false,
      };

    default:
      return state;
  }
};

export default notice;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Heading } from 'grommet';

import {
  Seo, FullHeightBox, ResponsiveImage, Container, AppButton,
} from '@Components/Control';

import { localAsset } from '@Helpers/asset';


const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <FullHeightBox fill justify="center" background="white">
      <Seo />
      <Container align="center" gap="large" pad="medium">
        <Box flex={false} width="20rem">
          <ResponsiveImage
            src={localAsset('images/logo/logo-full-color.svg')}
            srcMobile={localAsset('images/logo/logo-full-color.svg')}
            alt="Redflag Logo"
          />
        </Box>
        <Box gap="medium" align="center">
          <Heading level={1} size="large" textAlign="center" margin="none">
            404
          </Heading>
          <Heading thin level={2} size="large" textAlign="center" margin="none">
            Page Not Found
          </Heading>
          <Box width="100%" align="center" pad={{ top: '1rem' }}>
            <AppButton
              overrideHover
              width="7.25rem"
              height="3rem"
              onClick={() => navigate(-1)}
              level="dynamicLarge"
              color="white"
              bgColor="var(--app-highlight)"
              fontWeight={600}
              label="Go back"
            />
          </Box>
        </Box>
      </Container>
    </FullHeightBox>
  );
};

export default Error404Page;

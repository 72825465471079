import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import Mixpanel from '@Helpers/mixpanel';
import { selectEnvironment } from '@Helpers/util';
import ModalRenderer from './ModalRenderer';

const renderContent = (currentYear, bgColor) => (
  <Box flex pad={{ horizontal: 'medium' }} background={bgColor}>
    <p>
      Last Modified: January 01,
      {currentYear}
    </p>
    <h1>Acceptance of the Terms of Use</h1>
    <p>
      These terms of use are entered into by and between you and Redflag
      Artificial Intelligence, Inc. (&ldquo;Redflag&rdquo;, &ldquo;we&rdquo;
      or &ldquo;us&rdquo;).The following terms and conditions, together with any
      documents they expressly incorporate by reference (collectively, these
      &ldquo;Terms of Use&rdquo;), govern your access to and use of
      www.redflagai.co, including any content, functionality and services
      offered on or through the website (the &ldquo;Website&rdquo;), whether as
      a guest or a registered user.
    </p>
    <p>
      Please read the Terms of Use carefully before you start to use the
      Website. By using the Website or by clicking to accept or agree to the
      Terms of Use when this option is made available to you, you accept and
      agree to be bound and abide by these Terms of Use and our&nbsp;
      <a href="https://www.redflagai.co/#privacy">Privacy Policy</a>
      , incorporated herein by reference. If you do not want to agree to these
      Terms of Use or the Privacy Policy, you must not access or use the
      Website.&nbsp;
    </p>
    <p>
      This Website is offered and available to users who are 18 years of age or
      older. By using this Website, you represent and warrant that you are of
      legal age to form a binding contract with Redflag. If you do not meet this
      requirement, you must not access or use the Website.
    </p>
    <h1>Changes to the Terms of Use</h1>
    <p>
      We may revise and update these Terms of Use from time to time in our sole
      discretion. All changes are effective immediately when we post them, and
      apply to all access to and use of the Website thereafter. However, any
      changes to the dispute resolution provisions set forth in Governing Law
      and Jurisdiction will not apply to any disputes for which the parties have
      actual notice on or prior to the date the change is posted on the Website.
    </p>
    <p>
      Your continued use of the Website following the posting of revised Terms
      of Use means that you accept and agree to the changes. You are expected to
      check this page from time to time so you are aware of any changes, as they
      are binding on you.
    </p>
    <h1>Accessing the Website and Account Security</h1>
    <p>
      We reserve the right to withdraw or amend this Website, and any service or
      material we provide on the Website, in our sole discretion without notice.
      We will not be liable if for any reason all or any part of the Website is
      unavailable at any time or for any period. From time to time, we may
      restrict access to some parts of the Website, or the entire Website, to
      users, including registered users.
    </p>
    <p>You are responsible for:</p>
    <ul>
      <li>
        <p>
          Making all arrangements necessary for you to have access to the
          Website.
        </p>
      </li>
      <li>
        <p>
          Ensuring that all persons who access the Website through your internet
          connection are aware of these Terms of Use and comply with them.
        </p>
      </li>
    </ul>
    <p>
      To access the Website or some of the resources it offers, you may be asked
      to provide certain registration details or other information. It is a
      condition of your use of the Website that all the information you provide
      on the Website is correct, current and complete. You agree that all
      information you provide to register with this Website or otherwise is
      governed by our&nbsp;
      <a href="https://www.redflagai.co/#privacy">Privacy Policy</a>
      , and you consent to all actions we take with respect to your information
      consistent with our Privacy Policy.
    </p>
    <p>
      If you choose, or are provided with, a user name, password or any other
      piece of information as part of our security procedures, you must treat
      such information as confidential, and you must not disclose it to any
      other person or entity. You also acknowledge that your account is personal
      to you and agree not to provide any other person with access to this
      Website or portions of it using your user name, password or other security
      information. You agree to notify us immediately of any unauthorized access
      to or use of your user name or password or any other breach of security.
      You also agree to ensure that you exit from your account at the end of
      each session. You should use particular caution when accessing your
      account from a public or shared computer so that others are not able to
      view or record your password or other personal information.
    </p>
    <p>
      We have the right to disable any user name, password or other identifier,
      whether chosen by you or provided by us, at any time if, in our opinion,
      you have violated any provision of these Terms of Use.
    </p>
    <h1>Intellectual Property Rights</h1>
    <p>
      The Website and its entire contents, features and functionality
      (including but not limited to all information, software, text, displays,
      images, video and audio, and the design, selection and arrangement
      thereof), are owned by Redflag, its licensors or other providers of such
      material and are protected by United States and international copyright,
      trademark, patent, trade secret and other intellectual property or
      proprietary rights laws.
    </p>
    <p>
      These Terms of Use permit you to use the Website for your personal,
      non-commercial use only. You must not reproduce, distribute, modify,
      create derivative works of, publicly display, publicly perform, republish,
      download, store or transmit any of the material on our Website, except as
      follows:
    </p>
    <ul>
      <li>
        <p>
          Your computer may temporarily store copies of such materials in RAM
          incidental to your accessing and viewing those materials.
        </p>
      </li>
      <li>
        <p>
          You may store files that are automatically cached by your Web browser
          for display enhancement purposes.
        </p>
      </li>
      <li>
        <p>
          You may print or download one copy of a reasonable number of pages of
          the Website for your own personal, non-commercial use and not for
          further reproduction, publication or distribution.
        </p>
      </li>
    </ul>
    <p>You must not:</p>
    <ul>
      <li>
        <p>
          Modify copies of any materials from this site.
        </p>
      </li>
      <li>
        <p>
          Delete or alter any copyright, trademark or other proprietary rights
          notices from copies of materials from this site.
        </p>
      </li>
    </ul>
    <p>
      You must not access or use for any commercial purposes any part of the
      Website or any services or materials available through the Website.&nbsp;
    </p>
    <p>
      If you print, copy, modify, download or otherwise use or provide any other
      person with access to any part of the Website in breach of the Terms of
      Use, your right to use the Website will cease immediately and you must, at
      our option, return or destroy any copies of the materials you have made.
      No right, title or interest in or to the Website or any content on the
      Website is transferred to you, and all rights not expressly granted are
      reserved by the Redflag. Any use of the Website not expressly permitted
      by these Terms of Use is a breach of these Terms of Use and may violate
      copyright, trademark and other laws.
    </p>
    <h1>Trademarks</h1>
    <p>
      The Redflag name, the Redflag logo and all related names, logos, product
      and service names, designs and slogans are trademarks of the Redflag or
      its affiliates or licensors. You must not use such marks without the prior
      written permission of the Redflag. All other names, logos, product and
      service names, designs and slogans on this Website are the trademarks of
      their respective owners.
    </p>
    <h1>Prohibited Uses</h1>
    <p>
      You may use the Website only for lawful purposes and in accordance with
      these Terms of Use. You agree not to use the Website:
    </p>
    <ul>
      <li>
        <p>
          In any way that violates any applicable federal, state, local or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).
        </p>
      </li>
      <li>
        <p>
          For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise.
        </p>
      </li>
      <li>
        <p>
          To transmit, or procure the sending of, any advertising or
          promotional material, including any &ldquo;junk mail&rdquo;,
          &ldquo;chain letter&rdquo; or &ldquo;spam&rdquo; or any other similar
          solicitation.
        </p>
      </li>
      <li>
        <p>
          To impersonate or attempt to impersonate Redflag, a Redflag employee,
          another user or any other person or entity (including, without
          limitation, by using e-mail addresses associated with any of the
          foregoing).
        </p>
      </li>
      <li>
        <p>
          To engage in any other conduct that restricts or inhibits
          anyone&rsquo;s use or enjoyment of the Website, or which, as
          determined by us, may harm the Redflag or users of the Website or
          expose them to liability.
        </p>
      </li>
    </ul>
    <p>Additionally, you agree not to:</p>
    <ul>
      <li>
        <p>
          Use the Website in any manner that could disable, overburden, damage,
          or impair the site or interfere with any other party&rsquo;s use of
          the Website, including their ability to engage in real time activities
          through the Website.
        </p>
      </li>
      <li>
        <p>
          Use any robot, spider or other automatic device, process or means to
          access the Website for any purpose, including monitoring or copying
          any of the material on the Website.
        </p>
      </li>
      <li>
        <p>
          Use any manual process to monitor or copy any of the material on the
          Website or for any other unauthorized purpose without our prior
          written consent.
        </p>
      </li>
      <li>
        <p>
          Use any device, software or routine that interferes with the proper
          working of the Website.
        </p>
      </li>
      <li>
        <p>
          Introduce any viruses, trojan horses, worms, logic bombs or other
          material which is malicious or technologically harmful.
        </p>
      </li>
      <li>
        <p>
          Attempt to gain unauthorized access to, interfere with, damage or
          disrupt any parts of the Website, the server on which the Website is
          stored, or any server, computer or database connected to the Website.
        </p>
      </li>
      <li>
        <p>
          Attack the Website via a denial-of-service attack or a distributed
          denial-of-service attack.
        </p>
      </li>
      <li>
        <p>Otherwise attempt to interfere with the proper working of the Website.</p>
      </li>
    </ul>
    <h1>Monitoring and Enforcement; Termination</h1>
    <p>We have the right to:</p>
    <ul>
      <li>
        <p>
          Take appropriate legal action, including without limitation, referral
          to law enforcement, for any illegal or unauthorized use of the
          Website.
        </p>
      </li>
      <li>
        <p>
          Terminate or suspend your access to all or part of the Website for any
          violation of these Terms of Use.
        </p>
      </li>
    </ul>
    <h1>Copyright Infringement</h1>
    <p>
      We take claims of copyright infringement seriously. We will respond to
      notices of alleged copyright infringement that comply with applicable law.
      If you believe any materials accessible on or from the Website infringes
      your copyright, you may request removal of those materials (or access to
      them) from the Website by submitting written notification to our
      Copyright Agent (designated below). In accordance with the Online
      Copyright Infringement Liability Limitation Act of the Digital
      Millennium Copyright Act (17 U.S.C. &sect; 512)&nbsp;
      (&ldquo;DMCA&rdquo;), the written notice (the &ldquo;DMCA Notice&rdquo;)
      must include substantially the following:
    </p>
    <ul>
      <li>
        <p>Your physical or electronic signature.</p>
      </li>
      <li>
        <p>
          Identification of the copyrighted work you believe to have been
          infringed or, if the claim involves multiple works on the Website, a
          representative list of such works.
        </p>
      </li>
      <li>
        <p>
          Identification of the material you believe to be infringing in a
          sufficiently precise manner to allow us to locate that material.
        </p>
      </li>
      <li>
        <p>
          Adequate information by which we can contact you (including your name,
          postal address, telephone number and, if available, e-mail address).
        </p>
      </li>
      <li>
        <p>
          Include both of the following statements in the body of the DMCA
          Notice:
        </p>
      </li>
    </ul>
    <p>
      &ldquo;I hereby state that I have a good faith belief that the disputed
      use of the copyrighted material is not authorized by the copyright owner,
      its agent, or the law (e.g., as a fair use).&rdquo;
    </p>
    <p>
      &ldquo;I hereby state that the information in this DMCA Notice is accurate
      and, under penalty of perjury, that I am the owner, or authorized to act
      on behalf of the owner, of the copyright or of an exclusive right under
      the copyright that is allegedly infringed.&rdquo;
    </p>
    <h3>Our designated Copyright Agent to receive DMCA Notices is:</h3>
    <p>
      Legal Department
      <br />
      251 Little Falls Drive
      <br />
      Wilmington, DE 19808
      <br />
      accounts@redflagai.co
    </p>
    <p>
      If you fail to comply with all of the requirements of Section 512(c)(3) of
      the DMCA, your DMCA Notice may not be effective.
    </p>
    <p>
      Please be aware that if you knowingly materially misrepresent that
      material or activity on the Websites is infringing your copyright, you may
      be held liable for damages (including costs and attorneys&rsquo; fees)
      under Section 512(f) of the DMCA.
    </p>
    <p>
      Repeat Offenders:&nbsp;Please note that it is our policy in appropriate
      circumstances to disable and/or terminate the accounts of users who are
      repeat infringers.
    </p>
    <h1>Reliance on Information Posted</h1>
    <p>
      The information presented on or through the Website is made available
      solely for general information purposes. We do not warrant the accuracy,
      completeness or usefulness of this information. Any reliance you place on
      such information is strictly at your own risk. We disclaim all liability
      and responsibility arising from any reliance placed on such materials by
      you or any other visitor to the Website, or by anyone who may be informed
      of any of its contents.
    </p>
    <p>
      This Website may include content provided by third parties, including
      materials provided by other users, bloggers and third-party licensors,
      syndicators, aggregators and/or reporting services. All statements and/or
      opinions expressed in these materials, and all articles and responses to
      questions and other content, other than the content provided by the
      Redflag, are solely the opinions and the responsibility of the person or
      entity providing those materials. These materials do not necessarily
      reflect the opinion of the Redflag. We are not responsible, or liable to
      you or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </p>
    <h1>Changes to the Website</h1>
    <p>
      We may update the content on this Website from time to time, but its
      content is not necessarily complete or up-to-date. Any of the material on
      the Website may be out of date at any given time, and we are under no
      obligation to update such material.&nbsp;
    </p>
    <h1>Information About You and Your Visits to the Website</h1>
    <p>
      All information we collect on this Website is subject to our&nbsp;
      <a href="https://www.redflagai.co/#privacy">Privacy Policy</a>
      . By using the Website, you consent to all actions taken by us with
      respect to your information in compliance with the Privacy Policy.
    </p>
    <h1>Online Purchases and Other Terms and Conditions</h1>
    <p>
      All purchases through our site or other transactions for the sale of goods
      or services formed through the Website or as a result of visits made by
      you are governed by our Terms of Sale, which are hereby incorporated into
      these Terms of Use.
    </p>
    <p>
      Additional terms and conditions may also apply to specific portions,
      services or features of the Website. All such additional terms and
      conditions are hereby incorporated by this reference into these Terms of
      Use.
    </p>
    <h1>Links from the Website</h1>
    <p>
      If the Website contains links to other sites and resources provided by
      third parties, these links are provided for your convenience only. This
      includes links contained in advertisements, including banner
      advertisements and sponsored links. We have no control over the contents
      of those sites or resources, and accept no responsibility for them or for
      any loss or damage that may arise from your use of them. If you decide to
      access any of the third party websites linked to this Website, you do so
      entirely at your own risk and subject to the terms and conditions of use
      for such websites.
    </p>
    <h1>Geographic Restrictions</h1>
    <p>
      The owner of the Website is based in the United States. We make no claims
      that the Website or any of its content is accessible or appropriate
      outside of the United States. Access to the Website may not be legal by
      certain persons or in certain countries. If you access the Website from
      outside the United States, you do so on your own initiative and are
      responsible for compliance with local laws.
    </p>
    <h1>Disclaimer of Warranties</h1>
    <p>
      You understand that we cannot and do not guarantee or warrant that files
      available for downloading from the internet or the Website will be free of
      viruses or other destructive code. You are responsible for implementing
      sufficient procedures and checkpoints to satisfy your particular
      requirements for anti-virus protection and accuracy of data input and
      output, and for maintaining a means external to our Website for any
      reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
      DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
      TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
      COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF
      THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO
      YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
      TO IT.
    </p>
    <p>
      YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OBTAINED THROUGH THE
      WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES
      OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND
      &ldquo;AS AVAILABLE&rdquo; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
      EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED
      WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
      COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF
      THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
      ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
      WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
      WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL
      BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE
      OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES
      OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
      EXPECTATIONS.&nbsp;
    </p>
    <p>
      REDFLAG HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
      WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR
      PURPOSE.
    </p>
    <p>
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <h1>Limitation on Liability</h1>
    <p>
      IN NO EVENT WILL REDFLAG, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
      PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES
      OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
      YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
      CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
      INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
      INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
      EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
      ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
      WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
      OTHERWISE, EVEN IF FORESEEABLE.
    </p>
    <p>
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <h1>Indemnification</h1>
    <p>
      You agree to defend, indemnify and hold harmless the Redflag, its
      affiliates, licensors and service providers, and its and their respective
      officers, directors, employees, contractors, agents, licensors, suppliers,
      successors and assigns from and against any claims, liabilities, damages,
      judgments, awards, losses, costs, expenses or fees (including reasonable
      attorneys&rsquo; fees) arising out of or relating to your violation of
      these Terms of Use or your use of the Website, including, but not
      limited to, any use of the Website&rsquo;s content, services and
      products other than as expressly authorized in these Terms of Use or
      your use of any information obtained from the Website.
    </p>
    <h1>Governing Law and Jurisdiction</h1>
    <p>
      All matters relating to the Website and these Terms of Use and any dispute
      or claim arising therefrom or related thereto (in each case, including
      non-contractual disputes or claims), shall be governed by and construed
      in accordance with the internal laws of the State of Delaware without
      giving effect to any choice or conflict of law provision or rule
      (whether of the State of Delaware or any other jurisdiction).
    </p>
    <p>
      Any legal suit, action or proceeding arising out of, or related to, these
      Terms of Use or the Website shall be instituted exclusively in the federal
      courts of the United States or the courts of the State of Delaware,
      although we retain the right to bring any suit, action or proceeding
      against you for breach of these Terms of Use in your country of residence
      or any other relevant country. You waive any and all objections to the
      exercise of jurisdiction over you by such courts and to venue in such
      courts.
    </p>
    <h1>Arbitration</h1>
    <p>
      YOU AND REDFLAG ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A
      COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR
      REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD
      HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN
      ARBITRATION.
    </p>
    <p>
      ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE,
      WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY,
      CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND
      EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY
      TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE WEBSITE OR
      APPLICATIONS, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
      ARBITRATION.
    </p>
    <p>
      Arbitration uses a neutral arbitrator instead of a judge or jury, allows
      for more limited discovery than in court, and is subject to very limited
      review by courts. Arbitrators can award the same damages and relief that a
      court can award. The arbitration will be administered by the Judicial
      Arbitration and Mediation Services (&ldquo;JAMS&rdquo;) in accordance with
      the JAMS Comprehensive Arbitration Rules &amp; Procedures (the
      &ldquo;JAMS Rules&rdquo;) then in effect, except as modified by this
      Section 19. (The JAMS Rules are available at
      https://www.jamsadr.com/rules-comprehensive-arbitration/, by calling JAMS
      at 949-224-1810, or by contacting us at our Notice address listed below.)
      You agree that, by agreeing to these Terms, the Federal Arbitration Act
      will govern the interpretation and enforcement of this section.
    </p>
    <p>
      The arbitrator will have exclusive authority to resolve any dispute
      relating to arbitrability and/or enforceability of this arbitration
      provision, including any unconscionability challenge or any other
      challenge that the arbitration provision or the agreement is void,
      voidable or otherwise invalid. The arbitrator will be empowered to grant
      whatever relief would be available in court under law or in equity. Any
      award of the arbitrator will be final and binding on each of the parties
      and may be entered as a judgment in any court of competent jurisdiction.
    </p>
    <p>
      If you seek arbitration or elect to file a small claim court action, you
      must first send us, by certified mail, a written notice of your claim
      (a &ldquo;Notice&rdquo;) to Redflag in accordance with the Notice
      section below. If Redflag initiates the arbitration, we will
      send such Notice to you. A Notice, whether sent by you or Redflag, must:
      (a) describe the nature and basis of the claim or dispute; and (b)
      describe the specific relief sought (the &ldquo;Demand&rdquo;). If you and
      Redflag do not reach an agreement to resolve the claim within 30 days
      after the Notice is received, you or Redflag may commence an arbitration
      proceeding or file a claim in small claims court. The arbitration or
      small-claims court proceeding will be limited solely to your individual
      dispute or controversy. You may download or copy of form Notice and a form
      to initiate arbitration at https://www.jamsadr.com/adr-forms/.
    </p>
    <p>
      You agree to an arbitration on an individual basis.&nbsp;IN ANY DISPUTE,
      NEITHER YOU NOR REDFLAG WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY
      OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE
      PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A
      PRIVATE ATTORNEY GENERAL CAPACITY.&nbsp;The arbitral tribunal may not
      consolidate more than one person&rsquo;s claims and may not otherwise
      preside over any form of a representative or class proceeding. The
      arbitral tribunal has no power to consider the enforceability of this
      class arbitration waiver and any challenge to the class arbitration waiver
      may only be raised in a court of competent jurisdiction.
    </p>
    <p>
      This arbitration clause shall survive the termination of these Terms of
      Use. If this specific provision is found to be unenforceable, then (a) the
      entirety of this arbitration provision shall be null and void, but the
      remaining provisions of these Terms of Use shall remain in full force and
      effect; and (b) exclusive jurisdiction and venue for any claims will be as
      set forth above.
    </p>
    <h1>Limitation on Time to File Claims</h1>
    <p>
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
      THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.
    </p>
    <h1>Waiver and Severability</h1>
    <p>
      No waiver by Redflag of any term or condition set forth in these Terms of
      Use shall be deemed a further or continuing waiver of such term or
      condition or a waiver of any other term or condition, and any failure of
      Redflag to assert a right or provision under these Terms of Use shall not
      constitute a waiver of such right or provision.
    </p>
    <p>
      If any provision of these Terms of Use is held by a court or other
      tribunal of competent jurisdiction to be invalid, illegal or unenforceable
      for any reason, such provision shall be eliminated or limited to the
      minimum extent such that the remaining provisions of the Terms of Use will
      continue in full force and effect.
    </p>
    <h1>Entire Agreement</h1>
    <p>
      The Terms of Use, Privacy Policy and Terms of Sale constitute the sole and
      entire agreement between you and Redflag with respect to the Website and
      supersede all prior and contemporaneous understandings, agreements,
      representations and warranties, both written and oral, with respect to the
      Website.
    </p>
    <h1>Your Comments and Concerns</h1>
    <p>
      This website is operated by Redflag Artificial Intelligence Inc. at 2001
      Addison Street Suite 300, Berkeley CA 94704.
    </p>
    <p>
      All other feedback, comments, requests for technical support and other
      communications relating to the Website should be directed to:
      contact@redflagai.co.
    </p>
    <br />
  </Box>
);

const TermsOfUseModal = ({
  toggle,
  isSmall,
  currentYear,
  background = 'white',
  textColor = '#3C3C3C',
}) => {
  React.useEffect(() => {
    Mixpanel.track('modal_view', {
      modal: 'Terms of Use',
      environment: selectEnvironment(),
    });
  }, []);

  return (
    <ModalRenderer
      toggle={toggle}
      isSmall={isSmall}
      title="Redflag Website Terms of Use"
      renderContent={() => renderContent(currentYear, background)}
      background={background}
      textColor={textColor}
    />
  );
};

TermsOfUseModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  currentYear: PropTypes.number.isRequired,
  background: PropTypes.string,
  textColor: PropTypes.string,
};

export default TermsOfUseModal;

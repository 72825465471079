import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';

import {
  Box, Text, Layer, Button,
} from 'grommet';
import { FormView, FormClose } from 'grommet-icons';

import { AppButton } from '@Components/Control';
import {
  StyledRaisedContainer,
  StyledModalActivateButton,
  StyledModalSelectButton,
  StyledFormCloseContainer,
} from './StyledProductFormComponent';


const ModalSelect = ({
  small,
  handleFormValues,
  value = null,
  options = null,
  objectOptions = null,
  selectionKey = null,
  displayKey = null,
  description = null,
  fieldTitle,
  authPagesConfig: {
    primaryText,
    secondaryText,
    focusHighlight,
    hintText,
    buttonHighlight,
    selectButtonBg,
    darkModeColors,
  },
  inputConfig = null,
  darkmode = false,
  searchTypeKey,
  platformOptionOverrides = null,
  ...rest
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState([]);
  let selectableValues = options;

  const handleSelection = (uid) => {
    const prevSelected = [...selectedValues];
    if (prevSelected.includes(uid)) {
      _.pull(prevSelected, uid);
    } else {
      prevSelected.push(uid);
    }

    setSelectedValues(prevSelected);
  };

  let displayValues = value && value.join(', ');

  if (objectOptions) {
    selectableValues = objectOptions;

    displayValues = value && value.map((val) => {
      const obj = objectOptions?.find((dt) => dt?.[selectionKey] === val);
      return obj?.[displayKey];
    }).join(', ');
  }

  if (value?.length > 4) {
    displayValues = `${value.slice(0, 4).join(', ')} + ${value.length - 4} more`;

    if (objectOptions) {
      displayValues = `${objectOptions
        .filter((dt) => value.includes(dt[selectionKey]))
        .slice(0, 4)
        .map((dt) => dt[displayKey])
        .join(', ')} + ${value.length - 4} more`;
    }
  }

  if (platformOptionOverrides?.length > 0) {
    selectableValues = platformOptionOverrides;
  } else if (inputConfig?.supportedCategoryValues) {
    selectableValues = inputConfig.supportedCategoryValues[searchTypeKey];
  }

  const allowSelection = selectedValues?.length >= 1 || value?.length >= 1;

  const useTextColor = darkmode ? darkModeColors.primaryText : primaryText;
  const useIconColor = darkmode ? 'white' : secondaryText;
  const useFocusHighlight = darkmode ? darkModeColors.focusHighlight : focusHighlight;
  const useButtonHighlight = darkmode ? darkModeColors.menuButtonHighlight : buttonHighlight;
  const useBorderColor = darkmode ? darkModeColors.navBorder : '#D0D0D0';
  const useModalBg = darkmode ? darkModeColors.containerBg : 'white';
  const useSelectButtonBg = darkmode ? darkModeColors.selectButtonBg : selectButtonBg;
  const useSelectButtonHighlight = darkmode ? darkModeColors.secondaryText : buttonHighlight;
  const useUnselectedBg = darkmode ? darkModeColors.primaryDarkBg : 'white';

  const renderSelectableOptions = () => {
    if (!selectableValues || selectableValues?.length === 0) {
      return (
        <Box width="100%" pad={{ vertical: '1rem' }} align="center">
          <Text size="1rem" color={useTextColor}>
            No options available
          </Text>
        </Box>
      );
    }

    if (objectOptions && selectionKey && displayKey) {
      return (
        <Box wrap direction="row">
          {selectableValues?.map((dt) => (
            <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
              <StyledModalSelectButton
                plain
                selected={selectedValues.includes(dt[selectionKey])}
                textSize="1rem"
                onClick={() => handleSelection(dt[selectionKey])}
                bgColor={useUnselectedBg}
                primaryText={useTextColor}
                buttonHighlight={useSelectButtonHighlight}
                buttonBg={useSelectButtonBg}
                label={dt[displayKey]}
              />
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Box wrap direction="row">
        {selectableValues?.map((dt) => (
          <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
            <StyledModalSelectButton
              plain
              selected={selectedValues.includes(dt)}
              textSize="1rem"
              onClick={() => handleSelection(dt)}
              bgColor={useUnselectedBg}
              primaryText={useTextColor}
              buttonHighlight={useSelectButtonHighlight}
              buttonBg={useSelectButtonBg}
              label={dt}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <StyledModalActivateButton
        plain
        lessPad
        textSize="1rem"
        onClick={() => setShowModal(true)}
        modalOpen={showModal}
        highlight={useFocusHighlight}
        borderColor={useBorderColor}
        label={(
          <Box direction="row" justify="between" align="center">
            <Text size="0.8rem" color={(value && value.length) ? useTextColor : hintText}>
              {displayValues || 'Select options'}
            </Text>
            <FormView color={useIconColor} size="1.3rem" />
          </Box>
        )}
        {...rest}
      />
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => {
            if (allowSelection) handleFormValues(selectedValues);
            setShowModal(false);
          }}
          onClickOutside={() => {
            if (allowSelection) handleFormValues(selectedValues);
            setShowModal(false);
          }}
        >
          <StyledRaisedContainer
            gap="0.5rem"
            align="center"
            pad="1.5rem"
            width={{ min: small ? '96vw' : '50rem' }}
            bgColor={useModalBg}
          >
            <Box direction="row" width="100%" justify="between">
              <Text weight={600} size="1rem" color={useTextColor}>
                {fieldTitle}
              </Text>
              <Button
                plain
                onClick={() => {
                  if (allowSelection) handleFormValues(selectedValues);
                  setShowModal(false);
                }}
              >
                <StyledFormCloseContainer highlight={useButtonHighlight} direction="row" gap="0.25rem" align="center">
                  <Text weight={600} size="0.9rem" color={useTextColor}>
                    Close
                  </Text>
                  <FormClose color={useTextColor} size="1.2rem" />
                </StyledFormCloseContainer>
              </Button>
            </Box>
            {description && (
              <Box width="100%">
                <Text size={small ? '0.85rem' : '0.75rem'} color={hintText}>
                  {description}
                </Text>
              </Box>
            )}
            {renderSelectableOptions()}
            <Box
              width="100%"
              pad={{ top: '1rem' }}
              align="center"
              border={{
                color: '#D0D0D0',
                size: '1px',
                style: 'solid',
                side: 'top',
              }}
            >
              <AppButton
                overrideHover
                width="7.25rem"
                height="3rem"
                onClick={() => {
                  if (allowSelection) handleFormValues(selectedValues);
                  setShowModal(false);
                }}
                level="dynamicLarge"
                color="white"
                bgColor={useButtonHighlight}
                fontWeight={600}
                label="Finished"
              />
            </Box>
          </StyledRaisedContainer>
        </Layer>
      )}
    </Box>
  );
};

ModalSelect.propTypes = {
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
      focusHighlight: PropTypes.string.isRequired,
      navBorder: PropTypes.string.isRequired,
      selectButtonBg: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    supportedCategoryValues: PropTypes.objectOf(PropTypes.any),
  }),
  searchTypeKey: PropTypes.string.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  objectOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  selectionKey: PropTypes.string,
  displayKey: PropTypes.string,
  description: PropTypes.string,
  darkmode: PropTypes.bool,
  platformOptionOverrides: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default ModalSelect;

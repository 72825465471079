import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';
import Case from 'case';

import {
  Box, Text,
} from 'grommet';

import {
  AppButton, ActionButton, SimpleModal,
} from '@Components/Control';
import { ProductFormSingleTextInput } from '@Components/Partial/Product/NewSearch';
import { localAsset } from '@Helpers/asset';
import {
  renderTableHeader,
  NoDataPlaceholder,
  TableWrapper,
} from '../SharedComponents';
import {
  StyledSVG, StyledSortButton,
} from '../StyledSharedComponents';
import { StyledStatusBadge } from '../ListSearches/StyledListSearches';


const TableControls = ({
  highlight,
  primaryText,
  selectedSortOption,
  handleSort,
  handleAdd,
  isAdding,
  iconPath,
}) => (
  <Box direction="row" gap="0.5rem">
    <AppButton
      overrideHover
      onClick={() => handleAdd()}
      level="tableControls"
      color="white"
      bgColor={highlight}
      fontWeight={600}
      label={isAdding ? 'Cancel' : '+ Add User'}
    />
    <StyledSortButton
      plain
      overrideHover
      key="sort"
      textSize="1rem"
      color={primaryText}
      label={selectedSortOption.name}
      highlight={highlight}
      onClick={() => handleSort(selectedSortOption.direction === 'desc' ? 'asc' : 'desc')}
      icon={(
        <StyledSVG
          src={localAsset(iconPath)}
          width="1.1rem"
          height="1.1rem"
          title={selectedSortOption.name}
        />
      )}
    />
  </Box>
);

TableControls.propTypes = {
  highlight: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  selectedSortOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
};

const UsersTable = ({
  small,
  loading,
  headerMap,
  createUser,
  deleteUser,
  resendInvite,
  currentUserEmail,
  usersData = null,
  authPagesConfig: {
    primaryText,
    buttonHighlight,
    focusHighlight,
  },
}) => {
  const [isAdding, setIsAdding] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [formBody, setFormBody] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [sortedData, setSortedData] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState({ name: 'Sort A-Z', direction: 'desc' });

  React.useEffect(() => {
    if (usersData) {
      const initialData = [...usersData];
      const sorted = initialData.sort((a, b) => (a.firstName.localeCompare(b.firstName)));
      setSortedData(sorted);
    }
  }, [usersData]);

  const handleSort = (sortDirection) => {
    const prevData = [...sortedData];

    if (sortDirection === 'desc') {
      prevData.sort((a, b) => (a.firstName.localeCompare(b.firstName)));
      setSortOrder({ name: 'Sort A-Z', direction: 'desc' });
    } else {
      prevData.sort((a, b) => (b.firstName.localeCompare(a.firstName)));
      setSortOrder({ name: 'Sort Z-A', direction: 'asc' });
    }

    setSortedData(prevData);
  };

  const renderDataPoint = (uid, value) => {
    const useValue = uid === 'status' ? Case.capital(value) : value;

    return (
      <Text size="0.875rem" color={primaryText}>
        {useValue}
      </Text>
    );
  };

  const headerActions = _.find(headerMap, (({ uid }) => uid === 'actions'));

  const renderActionButtons = (dt) => {
    if (currentUserEmail === dt.email) {
      return (
        <Box
          key={uniqid()}
          width={headerActions?.size}
          pad={{ left: small ? '0' : '0.5rem', vertical: small ? '0.25rem' : '0' }}
          justify={small ? 'end' : 'start'}
          direction="row"
          gap="0.5rem"
        >
          <StyledStatusBadge background="#EDFDF5">
            <Text size="0.875rem" color="#22684B" weight={500}>
              Current user
            </Text>
          </StyledStatusBadge>
        </Box>
      );
    }

    return (
      <Box
        width={headerActions?.size}
        pad={{ left: small ? '0' : '0.5rem', top: small ? '1rem' : '0' }}
      >
        <Box direction="row" round="0.35rem" gap="0.5rem" background="white" justify={small ? 'end' : 'start'}>
          {dt.status === 'invited' && (
            <ActionButton
              key="resend-invite"
              small={small}
              label="Resend invite"
              iconPath="images/replace-content-icon.svg"
              textColor={primaryText}
              hoverColor={buttonHighlight}
              hoverTextColor="white"
              actionHandler={() => resendInvite(dt.id)}
            />
          )}
          <ActionButton
            key="delete-user"
            small={small}
            label="Delete user"
            iconPath="images/delete-content-icon.svg"
            textColor={primaryText}
            hoverColor={buttonHighlight}
            hoverTextColor="white"
            actionHandler={() => setDeleteId(dt.id)}
          />
        </Box>
      </Box>
    );
  };

  const renderTableContents = () => {
    if (loading || !sortedData || sortedData.length === 0) {
      return (
        <NoDataPlaceholder
          noShadow
          noRound
          showRefreshButton={false}
          buttonHighlight={buttonHighlight}
          loading={loading || !sortedData}
          label="Loading users..."
          textColor={primaryText}
          loaderColor={buttonHighlight}
        />
      );
    }

    return (
      <Box fill="horizontal" direction="column">
        {sortedData?.map((dt) => (
          <Box
            pad={{ vertical: '0.75rem' }}
            key={uniqid()}
            border={{
              color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
            }}
            direction={small ? 'column' : 'row'}
            fill="horizontal"
            align={small ? 'start' : 'center'}
          >
            {headerMap.map(({ uid, display, size }) => uid !== 'actions' && (
              <Box
                key={uniqid()}
                width={size}
                pad={{ left: small ? '0' : '0.5rem', vertical: small ? '0.25rem' : '0' }}
                direction="row"
                gap="0.5rem"
              >
                {small && (
                  <Text size="0.875rem" color="#84919A">
                    {display}
                    :
                  </Text>
                )}
                <Text size="0.875rem" color={primaryText}>
                  {renderDataPoint(uid, dt[uid])}
                </Text>
              </Box>
            ))}
            {renderActionButtons(dt)}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box direction="column" fill="horizontal">
      <TableWrapper
        small={small}
        header="Current Users"
        textColor={primaryText}
        boxPad={{ horizontal: small ? '1.5rem' : '2rem', top: '1rem', bottom: small ? '1.5rem' : '2rem' }}
        tableControls={(
          <TableControls
            small={small}
            highlight={buttonHighlight}
            primaryText={primaryText}
            displayInputValue={null}
            selectedSortOption={sortOrder}
            handleSort={(sortDirection) => handleSort(sortDirection)}
            handleAdd={() => setIsAdding(!isAdding)}
            isAdding={isAdding}
            iconPath={`images/sort-input-icon-${sortOrder.direction}.svg`}
          />
        )}
      >
        <Box>
          {!small && sortedData?.length > 0 && (
            <Box
              fill="horizontal"
              wrap={false}
              direction="row"
              border={{
                color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
              }}
            >
              {headerMap.map(({ uid, display, size }) => (
                <Box key={uid} width={size} pad={{ horizontal: '0.5rem', top: '1rem', bottom: '0.5rem' }}>
                  {renderTableHeader(display, small)}
                </Box>
              ))}
            </Box>
          )}
          {renderTableContents()}
        </Box>
        {isAdding && (
          <SimpleModal
            hideLowerSection
            noClickOutside
            small={small}
            toggleModal={setIsAdding}
            modalTitle="Add User"
            closeLabel="Finished"
            textColor={primaryText}
            highlightColor={focusHighlight}
            modalBg="white"
            desktopMinWidth="30rem"
          >
            <Box width="100%" direction="column" gap="1rem">
              <ProductFormSingleTextInput
                label="Email"
                value={formBody.email}
                type="email"
                handleFormValues={(val) => setFormBody({ ...formBody, email: val })}
                placeholder="Enter a valid email"
                focusHighlight={focusHighlight}
                small={small}
              />
              <ProductFormSingleTextInput
                label="First Name"
                value={formBody.firstName}
                handleFormValues={(val) => setFormBody({ ...formBody, firstName: val })}
                placeholder="Enter first name"
                focusHighlight={focusHighlight}
                small={small}
              />
              <ProductFormSingleTextInput
                label="Last Name"
                value={formBody.lastName}
                handleFormValues={(val) => setFormBody({ ...formBody, lastName: val })}
                placeholder="Enter last name"
                focusHighlight={focusHighlight}
                small={small}
              />
              <Box direction="row" gap="1rem">
                <AppButton
                  overrideHover
                  level="xsmall"
                  color="white"
                  bgColor={buttonHighlight}
                  label="Cancel"
                  onClick={() => {
                    setIsAdding(false);
                    setFormBody({
                      email: '',
                      firstName: '',
                      lastName: '',
                    });
                  }}
                />
                <AppButton
                  overrideHover
                  level="xsmall"
                  color="white"
                  disabled={!formBody.email || !formBody.firstName || !formBody.lastName}
                  bgColor={buttonHighlight}
                  label="Add User"
                  onClick={() => {
                    createUser(formBody);
                    setIsAdding(false);
                    setFormBody({
                      email: '',
                      firstName: '',
                      lastName: '',
                    });
                  }}
                />
              </Box>
            </Box>
          </SimpleModal>
        )}
        {deleteId && (
          <SimpleModal
            hideLowerSection
            noClickOutside
            small={small}
            toggleModal={setDeleteId}
            modalTitle="Confirm Deletion"
            closeLabel="Cancel"
            textColor={primaryText}
            highlightColor={focusHighlight}
            modalBg="white"
            desktopMinWidth="30rem"
          >
            <Box width="100%" direction="column" gap="1rem" pad={{ top: '0.5rem' }}>
              <Text size="0.875rem" color={primaryText}>
                Are you sure you wish to delete this user?
                This action is permanent and cannot be undone.
              </Text>
              <Box width="100%" direction="row" gap="1rem" justify="center">
                <AppButton
                  overrideHover
                  level="xsmall"
                  color="white"
                  bgColor={buttonHighlight}
                  label="Cancel"
                  onClick={() => setDeleteId(null)}
                />
                <AppButton
                  overrideHover
                  level="xsmall"
                  color="white"
                  bgColor="#EC2C2C"
                  label="Delete User"
                  onClick={() => {
                    deleteUser(deleteId);
                    setDeleteId(null);
                  }}
                />
              </Box>
            </Box>
          </SimpleModal>
        )}
      </TableWrapper>
    </Box>
  );
};

UsersTable.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      searchPriorityIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  usersData: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired),
  createUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  currentUserEmail: PropTypes.string.isRequired,
};

export default UsersTable;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uniqid from 'uniqid';

import {
  Box, Button, Text,
} from 'grommet';
import {
  FormClose,
  StatusWarning,
} from 'grommet-icons';

import { closeCustomNoticeRequest } from '@Actions';
import { StyledLayer } from './Notification';


const StyledText = styled(Text)`
  color: white;
  white-space: pre-line;
`;

export const StyledCloseContainer = styled(Box)`
  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    border-color: ${({ highlight }) => highlight};

    span {
      color: ${({ highlight }) => highlight};
    }

    svg {
      stroke: ${({ highlight }) => highlight};
      fill: ${({ highlight }) => highlight};
      transform: scale(1.15);
    }
  }
`;

const CustomNotification = ({
  customMessage, closeCustomNotice,
}) => {
  if (!customMessage || customMessage instanceof Error) {
    return null;
  }

  const messageIsString = typeof customMessage === 'string';

  return (
    <StyledLayer
      transparent
      responsive={false}
      onEsc={closeCustomNotice}
      onClickOutside={closeCustomNotice}
    >
      <Box
        align="center"
        justify="center"
        direction="column"
        round="8px"
        background="var(--app-warning)"
        width={{ min: '15rem' }}
        pad="1rem"
        gap="0.75rem"
      >
        <Box
          direction="row"
          justify="between"
          width="100%"
          border={{
            side: 'bottom',
            style: 'solid',
            color: 'white',
            size: '1px',
          }}
          pad={{ bottom: '0.35rem' }}
        >
          <Box direction="row" gap="0.5rem" align="center">
            <StatusWarning size="1.15rem" color="white" />
            <Text weight={500} size="1.15rem" color="white">
              Errors
            </Text>
          </Box>
          <Button
            plain
            onClick={closeCustomNotice}
          >
            <StyledCloseContainer round align="center" highlight="white">
              <FormClose color="white" size="1.35rem" />
            </StyledCloseContainer>
          </Button>
        </Box>
        <Box width="100%" align="start">
          {messageIsString ? (
            <StyledText size="1.1rem">{customMessage}</StyledText>
          ) : (
            <Box direction="column" gap="0.5rem">
              {customMessage.map((msg) => (
                <StyledText key={uniqid()} size="1.1rem">{msg}</StyledText>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </StyledLayer>
  );
};

CustomNotification.propTypes = {
  customMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  closeCustomNotice: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { customMessage } = state.notice;
  return { customMessage };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeCustomNotice: closeCustomNoticeRequest }, dispatch);
}

CustomNotification.propTypes = {
  customMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  closeCustomNotice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomNotification);

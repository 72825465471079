import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Image, Text,
} from 'grommet';
import { FormPrevious } from 'grommet-icons';

import { Seo, FullHeightBox, Container } from '@Components/Control';
import { localAsset } from '@Helpers/asset';
import { paths } from '@Components/configs';

import {
  StyledForm, StyledTitle, StyledAnchor,
} from './StyledSubmissionComponent';


const BackButton = ({
  path, onClick, children,
}) => {
  if (onClick) {
    return (
      <StyledAnchor onClick={onClick}>
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledAnchor path={path}>
      {children}
    </StyledAnchor>
  );
};

BackButton.propTypes = {
  path: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const SubmissionLayout = ({
  seoConfig = null,
  small,
  withBackButton = false,
  backButtonLabel = 'Back',
  backButtonPath = paths.login,
  backButtonClick = null,
  bottomPad = '0',
  title,
  bgColor,
  bgImage = null,
  textColor = 'white',
  children,
  customWidth = null,
  ...rest
}) => (
  <Box background={bgColor} overflow="hidden" {...rest}>
    <FullHeightBox
      fill
      justify="center"
      data-bg={bgImage && bgImage}
      background={bgImage && { image: small ? `url(${bgImage}&w=1024)` : `url(${bgImage})` }}
    >
      <Seo {...seoConfig} />
      <Container
        align="center"
        justify="center"
        pad={{ top: '5.2rem', horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', bottom: bottomPad }}
      >
        <StyledForm
          pad="1.5rem"
          align="center"
          justify="start"
          gap="1rem"
          customWidth={customWidth}
        >
          <Box width="100%" justify="start">
            {withBackButton && (
              <BackButton path={backButtonClick ? null : backButtonPath} onClick={backButtonClick}>
                <Box direction="row" align="center" gap="0.15rem">
                  <FormPrevious size="0.85rem" color="#038BFC" />
                  <Text size="0.85rem">{backButtonLabel}</Text>
                </Box>
              </BackButton>
            )}
            <Box width="100%" direction="column" gap="0.65rem" align="center" justify="center">
              <Box width="2rem" height="2rem">
                <Image
                  src={localAsset('images/logo/logo-image-only-color.svg')}
                  alt={`${title} icon`}
                  fit="contain"
                  width="2rem"
                  height="2rem"
                />
              </Box>
              <StyledTitle size="1.35rem" textAlign="center" color={textColor}>
                {title}
              </StyledTitle>
            </Box>
          </Box>
          <Box fill pad={{ bottom: '1rem' }}>
            {children}
          </Box>
        </StyledForm>
      </Container>
    </FullHeightBox>
  </Box>
);

SubmissionLayout.propTypes = {
  seoConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  small: PropTypes.bool.isRequired,
  withBackButton: PropTypes.bool,
  backButtonLabel: PropTypes.string,
  backButtonPath: PropTypes.string,
  backButtonClick: PropTypes.func,
  bottomPad: PropTypes.string,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  bgImage: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  customWidth: PropTypes.string,
};

export default SubmissionLayout;

import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { FullHeightBox, AppLoader } from '@Components/Control';
import { Notification, CustomNotification, GuidedTour } from '@Components/Modal';
import Header from './Header';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';

const Container = ({
  small,
  authPageActive,
  location,
  authPagesConfig = null,
  searchEnabled = false,
  reportsEnabled = false,
  cyclopsEnabled = false,
  darkMode = false,
}) => {
  const selectHeader = () => {
    if (authPageActive) {
      return (
        <AuthHeader
          small={small}
          darkMode={darkMode}
          authPagesConfig={authPagesConfig}
          location={location}
          searchEnabled={searchEnabled}
          reportsEnabled={reportsEnabled}
          cyclopsEnabled={cyclopsEnabled}
        />
      );
    }

    return (
      <Header darkHeader={location.pathname !== '/shared-report'} small={small} />
    );
  };

  return (
    <>
      {selectHeader()}
      <AppLoader />
      <Notification />
      <CustomNotification />
      <GuidedTour />
      <FullHeightBox noNav className="tour-app-container">
        <Outlet />
      </FullHeightBox>
      {!authPageActive && (
        <AuthFooter
          small={small}
          darkMode={darkMode}
          config={authPagesConfig.footerConfig}
          authPagesConfig={authPagesConfig}
        />
      )}
    </>
  );
};

Container.propTypes = {
  small: PropTypes.bool.isRequired,
  authPageActive: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  searchEnabled: PropTypes.bool,
  reportsEnabled: PropTypes.bool,
  cyclopsEnabled: PropTypes.bool,
  darkMode: PropTypes.bool,
  authPagesConfig: PropTypes.shape({
    headerBg: PropTypes.string.isRequired,
    pageBg: PropTypes.string.isRequired,
    sideNavBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    footerConfig: PropTypes.shape({
      lowerBgColor: PropTypes.string.isRequired,
      lowerTextColor: PropTypes.string.isRequired,
      secondaryLowerTextColor: PropTypes.string.isRequired,
      highlightColor: PropTypes.string.isRequired,
    }).isRequired,
    icons: PropTypes.objectOf(PropTypes.any.isRequired),
    navIcons: PropTypes.objectOf(PropTypes.any.isRequired),
    listMenuMap: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    hideBlacklightSearchCompanies: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    showStatusMessage: PropTypes.bool,
    statusMessageConfig: PropTypes.shape({
      message: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      statusDotColor: PropTypes.string.isRequired,
    }),
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default Container;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';
import {
  Box, Text, Button, Image,
} from 'grommet';
import { FormNext } from 'grommet-icons';

import { AppButton, SubmissionLoader } from '@Components/Control';
import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import { shortenInt, selectEnvironment } from '@Helpers/util';
import Mixpanel from '@Helpers/mixpanel';


const StyledSelectButton = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 5px;
  font-size: 1rem;
  color: ${({ primaryText }) => primaryText};
  background-color: white;
  transition: all 0.3s ease;

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    color: ${({ buttonHighlight }) => buttonHighlight};

    svg {
      transform: translateX(6px);
    }
  }
`;

const StyledText = styled(Text)`
  line-height: 1.5;
  font-size: 1rem;
`;

const SubscriberForm = ({
  small,
  bgColor,
  bgImage,
  textColor,
  buttonHighlight,
  fetchVerticalTiers,
  setChannelLink,
  channelDataLoading,
  confirmSubscriberDetails,
  subscriberData = null,
  channelLink = '',
}) => {
  const [activeDisplay, setActiveDisplay] = React.useState('select-vertical');
  /* eslint-disable camelcase */
  const [requestBody, setRequestBody] = React.useState({
    vertical: '',
    platform: '',
    handle: '',
    channel_id: '',
  });

  React.useEffect(() => {
    if (subscriberData && !channelDataLoading) {
      setActiveDisplay('confirm-details');
    }
  }, [subscriberData, channelDataLoading]);

  const detailsAdded = requestBody.handle || requestBody.channel_id;
  const submitDisabled = !requestBody.vertical || !requestBody.platform
    || !detailsAdded || channelDataLoading || !channelLink;

  const renderContent = () => {
    switch (activeDisplay) {
      case 'confirm-details':
        return (
          <Box direction="column" gap="1rem">
            <StyledText color={textColor}>
              {`Hello, ${subscriberData.channelTitle}!`}
            </StyledText>
            <StyledText color={textColor}>
              Please confirm that these details are correct and
              this channel belongs to you:
            </StyledText>
            <Box
              direction="column"
              pad="0.75rem"
              round="8px"
              border={{ color: buttonHighlight, size: '1px' }}
              gap="0.5rem"
            >
              <Box direction="row" gap="0.5rem" align="center">
                <Box round="50%" width="3rem" height="3rem">
                  <Image
                    src={subscriberData.channelThumbnail}
                    alt="Subscriber logo"
                    fit="contain"
                    width="3rem"
                    height="3rem"
                  />
                </Box>
                <Text size="1.15rem" color={textColor} weight={600}>
                  {subscriberData.channelTitle}
                </Text>
              </Box>
              <Text size="0.85rem" color={textColor}>
                {subscriberData.channelDescription}
              </Text>
              <Text size="0.85rem" color={textColor}>
                {`${shortenInt(parseInt(subscriberData.channelVideoCount, 10))} uploaded videos`}
              </Text>
            </Box>
            <AppButton
              overrideHover
              onClick={() => {
                confirmSubscriberDetails();
                Mixpanel.track('subscriber_channel_confirmed', {
                  environment: selectEnvironment(),
                  channelLink,
                  channelTitle: subscriberData.channelTitle,
                  channelId: requestBody.channel_id,
                  channelHandle: requestBody.handle,
                });
              }}
              level="authSubmit"
              bgColor={buttonHighlight}
              color="white"
              label="Confirm details"
              alignSelf="center"
            />
          </Box>
        );
      case 'select-vertical':
        return (
          <Box direction="column" gap="1rem" justify="center">
            <StyledText color={textColor} textAlign="center">
              Which option best describes your role?
            </StyledText>
            <StyledSelectButton
              plain
              selected={false}
              onClick={() => {
                setActiveDisplay('content-creator');
                setRequestBody({
                  ...requestBody,
                  vertical: 'content_creator',
                  platform: 'YouTube',
                });
              }}
              primaryText={textColor}
              buttonHighlight={buttonHighlight}
              buttonBg="#F0F6FF"
              label="I'm a YouTube Content Creator"
              icon={<FormNext size="1.15rem" color={buttonHighlight} />}
            />
            <StyledSelectButton
              plain
              selected={false}
              onClick={() => setActiveDisplay('book-demo')}
              primaryText={textColor}
              buttonHighlight={buttonHighlight}
              buttonBg="#F0F6FF"
              label="I'm responsible for protecting Live Content (sports, etc.)"
              icon={<FormNext size="1.15rem" color={buttonHighlight} />}
            />
            <StyledSelectButton
              plain
              selected={false}
              onClick={() => setActiveDisplay('book-demo')}
              primaryText={textColor}
              buttonHighlight={buttonHighlight}
              buttonBg="#F0F6FF"
              label="I work for a Publisher"
              icon={<FormNext size="1.15rem" color={buttonHighlight} />}
            />
            <StyledSelectButton
              plain
              selected={false}
              onClick={() => setActiveDisplay('book-demo')}
              primaryText={textColor}
              buttonHighlight={buttonHighlight}
              buttonBg="#F0F6FF"
              label="I work for a Film Studio or Distributor"
              icon={<FormNext size="1.15rem" color={buttonHighlight} />}
            />
            <StyledSelectButton
              plain
              selected={false}
              onClick={() => setActiveDisplay('book-demo')}
              primaryText={textColor}
              buttonHighlight={buttonHighlight}
              buttonBg="#F0F6FF"
              label="None of these options"
              icon={<FormNext size="1.15rem" color={buttonHighlight} />}
            />
          </Box>
        );
      case 'content-creator':
        return (
          <Box direction="column" gap="1rem">
            <StyledText color={textColor}>
              Please provide details about your YouTube channel
              so we can find the right plan for you.
            </StyledText>
            <StyledText color={textColor}>
              Only one of channel handle or ID is required.
            </StyledText>
            <Box direction="column">
              <StyledSubmissionField required name="channelLink" htmlFor="channelLink" type="text">
                <StyledSubmissionInput
                  plain
                  data-tip="Full channel link e.g. www.youtube.com/@GoogleDevelopers"
                  data-for="channelLink-tooltip"
                  name="channelLink"
                  type="text"
                  placeholder="Full channel link"
                  highlight={buttonHighlight}
                  onChange={(e) => setChannelLink(e.target.value)}
                  value={channelLink}
                />
                <ReactTooltip
                  id="channelLink-tooltip"
                  place="right"
                  effect="solid"
                  tooltip
                />
              </StyledSubmissionField>
              <StyledSubmissionField required name="handle" htmlFor="handle" type="text">
                <StyledSubmissionInput
                  plain
                  data-tip="Channel handle e.g. @GoogleDevelopers"
                  data-for="handle-tooltip"
                  name="handle"
                  type="text"
                  placeholder="Channel handle"
                  highlight={buttonHighlight}
                  onChange={(e) => setRequestBody({ ...requestBody, handle: e.target.value })}
                  value={requestBody.handle}
                />
                <ReactTooltip
                  id="handle-tooltip"
                  place="right"
                  effect="solid"
                  tooltip
                />
              </StyledSubmissionField>
              <StyledSubmissionField required name="channel_id" htmlFor="channel_id" type="text">
                <StyledSubmissionInput
                  plain
                  data-tip="Channel ID e.g. UC_x5XG1OV2P6uZZ5FSM9Ttw"
                  data-for="channel_id-tooltip"
                  name="channel_id"
                  type="text"
                  placeholder="Channel ID"
                  highlight={buttonHighlight}
                  onChange={(e) => setRequestBody({ ...requestBody, channel_id: e.target.value })}
                  value={requestBody.channel_id}
                />
                <ReactTooltip
                  id="channel_id-tooltip"
                  place="right"
                  effect="solid"
                  tooltip
                />
              </StyledSubmissionField>
              <AppButton
                overrideHover
                onClick={() => fetchVerticalTiers(requestBody)}
                level="authSubmit"
                disabled={submitDisabled}
                bgColor={buttonHighlight}
                color="white"
                label="Submit details"
                alignSelf="center"
              />
            </Box>
            <Button plain onClick={() => setActiveDisplay('select-vertical')} align="center" justify="center">
              <Box align="center" justify="center">
                <Text size="0.85rem" color="#038BFC" textAlign="center">I&apos;m not a YouTube content creator</Text>
              </Box>
            </Button>
          </Box>
        );
      case 'book-demo':
        return (
          <Box direction="column" gap="1rem">
            <StyledText color={textColor}>
              Our team would like to know more about your needs
              to provide you with the optimal content protection solution.
            </StyledText>
            <StyledText color={textColor}>
              Please visit our site and book a demo
              to start protecting your digital assets!
            </StyledText>
            <AppButton
              overrideHover
              href="https://www.redflagai.co/contact"
              level="authSubmit"
              bgColor={buttonHighlight}
              color="white"
              label="Book a demo"
              alignSelf="center"
            />
          </Box>
        );
      default:
        return null;
    }
  };
  /* eslint-enable camelcase */

  return (
    <Box>
      <SubmissionLayout
        withBackButton={activeDisplay !== 'select-vertical'}
        backButtonClick={() => setActiveDisplay('select-vertical')}
        seoConfig={null}
        title="Sign up"
        bgColor={bgColor}
        bgImage={bgImage}
        textColor={textColor}
        small={small}
      >
        <Box direction="column">
          {renderContent()}
          {channelDataLoading && (
            <SubmissionLoader
              textColor={textColor}
              loaderColor={buttonHighlight}
              message="Loading YouTube channel data..."
              contentWidth="18rem"
            />
          )}
        </Box>
      </SubmissionLayout>
    </Box>
  );
};

SubscriberForm.propTypes = {
  small: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  fetchVerticalTiers: PropTypes.func.isRequired,
  setChannelLink: PropTypes.func.isRequired,
  channelDataLoading: PropTypes.bool.isRequired,
  channelLink: PropTypes.string,
  confirmSubscriberDetails: PropTypes.func.isRequired,
  subscriberData: PropTypes.shape({
    channelTitle: PropTypes.string.isRequired,
    channelThumbnail: PropTypes.string,
    channelDescription: PropTypes.string.isRequired,
    channelVideoCount: PropTypes.string.isRequired,
  }),
};

export default SubscriberForm;

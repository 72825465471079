import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';


const ProductRowStats = ({
  label, color, stat = null, isLast = false,
}) => (
  <Box
    pad={{ horizontal: '0.65rem' }}
    border={!isLast && { color: '#D0D0D0', style: 'solid', side: 'right' }}
  >
    <Text
      uppercase
      weight={600}
      size="1rem"
      color={color}
      style={{ letterSpacing: '0.216px' }}
    >
      {label}
      &nbsp;
      {stat || 'N/A'}
    </Text>
  </Box>
);

ProductRowStats.propTypes = {
  label: PropTypes.string.isRequired,
  stat: PropTypes.number,
  color: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
};

export default ProductRowStats;

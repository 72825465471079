import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';

import { Form, Box } from 'grommet';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { AppButton, Spinning } from '@Components/Control';


const stripePromise = loadStripe(process.env.STRIPE_KEY);

const StyledCardElement = styled(CardElement)`
  padding: 0.65rem 1rem;
  height: fit-content;
  border: 1px solid #D0D0D0;
  border-radius: 5px;

  > div {
    transform: none;
  }

  &:focus {
    border: 1px solid #038BFC;
  }
`;

const FormContent = ({
  submitMessage,
  checkOutLoading,
  checkout,
  buttonHighlight,
  submitDisabled,
  redirectPath = null,
  requestBody = null,
  updateOnly = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (updateOnly) {
      checkout({ card, stripe }, redirectPath);
    } else {
      checkout(requestBody, { card, stripe }, redirectPath);
    }
  };

  const disabledState = checkOutLoading || !stripe || !elements || submitDisabled;

  let size = '16px';

  if (window.innerWidth > 1919) {
    size = '20px';
  } else if (window.innerWidth < 769) {
    size = '12px';
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <StyledCardElement
        options={{
          disabled: disabledState,
          hidePostalCode: true,
          style: {
            base: {
              fontFamily: '"Figtree", sans-serif',
              fontSize: size,
              color: '#3C3C3C',
              iconColor: buttonHighlight,
              '::placeholder': { color: '#a2a2a2' },
            },
            complete: { iconColor: '#22684B' },
            invalid: { iconColor: '#EC2C2C', color: '#EC2C2C' },
          },
        }}
      />
      <Box pad={{ top: '1rem' }}>
        <AppButton
          overrideHover
          type="submit"
          level="authSubmit"
          disabled={disabledState}
          bgColor={buttonHighlight}
          color="white"
          label={submitMessage}
          alignSelf="center"
        />
      </Box>
    </Form>
  );
};

FormContent.propTypes = {
  requestBody: PropTypes.object,
  checkOutLoading: PropTypes.bool.isRequired,
  checkout: PropTypes.func.isRequired,
  submitMessage: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
  buttonHighlight: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  updateOnly: PropTypes.bool,
};

const PaymentForm = ({
  checkOutLoading,
  checkout,
  submitMessage,
  submitDisabled,
  buttonHighlight,
  redirectPath = null,
  requestBody = null,
  updateOnly = false,
}) => {
  if (checkOutLoading) {
    return (
      <Box width="100%" align="center" justify="center">
        <Spinning size="large" color={buttonHighlight} />
      </Box>
    );
  }

  const options = {
    fonts: [{
      cssSrc: 'https://fonts.googleapis.com/css2?family=Figtree:wght@300..900&display=swap',
    }],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <FormContent
        requestBody={requestBody}
        checkOutLoading={checkOutLoading}
        checkout={checkout}
        submitMessage={submitMessage}
        redirectPath={redirectPath}
        buttonHighlight={buttonHighlight}
        submitDisabled={submitDisabled}
        updateOnly={updateOnly}
      />
    </Elements>
  );
};

PaymentForm.propTypes = {
  requestBody: PropTypes.object,
  checkOutLoading: PropTypes.bool.isRequired,
  checkout: PropTypes.func.isRequired,
  submitMessage: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
  buttonHighlight: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  updateOnly: PropTypes.bool,
};

export default PaymentForm;

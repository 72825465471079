import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { FormInput, AddButton } from './ProductFormComponent';

const renderNewInput = () => ({ id: uniqid(), val: '', added: false });
const renderPrevInputs = (val) => ({ id: uniqid(), val, added: true });
const extractValues = (data) => data.filter((dt) => dt.added).map((dt) => dt.val);

const MultipleTextInputs = ({
  maxInputs = null,
  value = [],
  handleFormValues,
  focusHighlight,
  bgColor = 'white',
  borderColor = null,
  placeholder = null,
  buttonAlign = 'start',
}) => {
  const [inputs, setInputs] = React.useState([renderNewInput()]);

  React.useEffect(() => {
    if (value && value.length) {
      const prevInputs = value.map((dt) => (renderPrevInputs(dt)));

      if (!maxInputs || prevInputs.length < maxInputs) {
        prevInputs.push(renderNewInput());
      }

      setInputs(prevInputs);
    }
  }, []);

  const addInput = () => {
    if (inputs[inputs.length - 1].val) {
      const addInputs = [...inputs];
      inputs[inputs.length - 1].added = true;

      if (!maxInputs || inputs.length < maxInputs) {
        addInputs.push(renderNewInput());
      }

      setInputs(addInputs);
      handleFormValues(extractValues(inputs));
    }
  };

  const removeInput = (index) => {
    const remInputs = inputs.filter((_, i) => i !== index);
    const { length } = remInputs;

    if (remInputs[length - 1].added) {
      remInputs.push(renderNewInput());
    }

    setInputs(remInputs);
    handleFormValues(extractValues(remInputs));
  };

  const handleValue = (index, e) => {
    const newInputs = [...inputs];
    newInputs[index].val = e.target.value;

    setInputs(newInputs);
  };

  return (
    <Box gap="small">
      {inputs.map(({ id, val, added }, i) => (
        <Box key={id}>
          <Box flex direction="row" gap="0.75rem">
            <Box flex key={id} pad={{ bottom: '0.75rem' }} justify="center">
              <FormInput
                inputAdded={added}
                disabled={added}
                value={val}
                onChange={(e) => handleValue(i, e)}
                highlight={focusHighlight}
                bgColor={bgColor}
                borderColor={borderColor}
                placeholder={placeholder}
              />
            </Box>
            {added && (
              <Box width="1rem" align="center" justify="center" pad={{ bottom: '0.75rem' }}>
                <Button
                  plain
                  icon={<FormClose size="1.25rem" color="#636363" />}
                  onClick={() => removeInput(i)}
                />
              </Box>
            )}
          </Box>
          {!added && (
            <AddButton
              disabled={!val}
              buttonAlign={buttonAlign}
              onClick={() => addInput()}
              highlight={focusHighlight}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

MultipleTextInputs.propTypes = {
  maxInputs: PropTypes.number,
  handleFormValues: PropTypes.func.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  placeholder: PropTypes.string,
  buttonAlign: PropTypes.string,
};

export default MultipleTextInputs;

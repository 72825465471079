import {
  VERTICAL_TIERS_LOADED,
  VERTICAL_TIERS_CLEAR,
  CUSTOMER_SUBSCRIPTION_LOADED,
  CUSTOMER_SUBSCRIPTION_CLEAR,
} from '../actions/actionTypes';

const initialState = {
  verticalTiers: null,
  subscriptionData: null,
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case VERTICAL_TIERS_LOADED:
      return { ...state, verticalTiers: action.payload.verticalTiers };

    case VERTICAL_TIERS_CLEAR:
      return { ...state, verticalTiers: null };

    case CUSTOMER_SUBSCRIPTION_LOADED:
      return { ...state, subscriptionData: action.payload.subscription };

    case CUSTOMER_SUBSCRIPTION_CLEAR:
      return { ...state, subscriptionData: null };

    default:
      return state;
  }
};

export default payment;

import mixpanel from 'mixpanel-browser';


const envCheck = process.env.NODE_ENV === 'production';

const Mixpanel = {
  init: () => {
    /* eslint-disable camelcase */
    mixpanel.init(process.env.MIXPANEL_TOKEN, {
      debug: !envCheck,
      ignore_dnt: true,
      track_pageview: envCheck ? 'url-with-path' : false,
    });
    /* eslint-enable camelcase */
  },
  track: (eventName, properties) => {
    if (envCheck) mixpanel.track(eventName, properties);
  },
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  register: (properties) => {
    if (envCheck) mixpanel.register(properties);
  },
};

export { Mixpanel as default };

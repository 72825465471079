import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'grommet';
import { Copy } from 'grommet-icons';
import ReactTooltip from 'react-tooltip';


const StyledCopyButton = styled(Button)`
  .copyTooltip {
    font-family: inherit;
    border-radius: 4px;
    background-color: ${({ tooltipColor }) => tooltipColor};
  }

  &:hover svg {
    fill: ${({ hoverColor }) => hoverColor};
    stroke: ${({ hoverColor }) => hoverColor};
  }
`;

const CopyButton = ({
  renderKey,
  value,
  color,
  hoverColor,
  tooltipColor = 'black',
  tooltipPosition = 'right',
  hideTooltip = false,
  copyMessage = 'Copy to clipboard',
  ...rest
}) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <StyledCopyButton
      plain
      data-tip={copied ? 'Copied!' : copyMessage}
      data-for={`${renderKey}-tooltip`}
      key={copied ? `${renderKey}-copied` : renderKey}
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true);
      }}
      hoverColor={hoverColor}
      tooltipColor={tooltipColor}
      {...rest}
    >
      <Copy color={color} size="0.85rem" />
      {!hideTooltip && (
        <ReactTooltip
          id={`${renderKey}-tooltip`}
          className="copyTooltip"
          place={tooltipPosition}
          arrowColor={tooltipColor}
          effect="solid"
          tooltip
        />
      )}
    </StyledCopyButton>
  );
};

CopyButton.propTypes = {
  renderKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
  tooltipColor: PropTypes.string,
  hideTooltip: PropTypes.bool,
  copyMessage: PropTypes.string,
};

export default CopyButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import _ from 'lodash';

import { AppButton, SimpleModal } from '@Components/Control';
import { StyledModalSelectButton } from '../Product/NewSearch/StyledProductFormComponent';


const PlatformSelector = ({
  small,
  platforms,
  textColor,
  buttonHighlight,
  selectedPlatforms,
  setSelectedPlatforms,
  limit,
  selectedTierKey = null,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState(selectedPlatforms);

  React.useEffect(() => {
    setSelectedValues([]);
  }, [selectedTierKey]);

  const handleSelection = (platform) => {
    const prevSelected = [...selectedValues];
    if (prevSelected.includes(platform)) {
      _.pull(prevSelected, platform);
    } else if (selectedValues.length < limit) {
      prevSelected.push(platform);
    }

    setSelectedValues(prevSelected);
  };

  const modalLabel = limit === 1 ? 'Select a platform' : `Select ${limit} platforms`;
  let buttonLabel = limit === 1 ? 'Select a platform' : `Select ${limit} platforms`;

  if (selectedValues.length > 0) {
    buttonLabel = `${selectedValues.join(', ')} selected`;
  }

  return (
    <Box direction="column" gap="1rem">
      <AppButton
        overrideHover
        onClick={() => setShowModal(!showModal)}
        level="authSubmit"
        bgColor={buttonHighlight}
        color="white"
        label={buttonLabel}
        alignSelf="center"
      />
      {showModal && (
        <SimpleModal
          hideLowerSection
          small={small}
          toggleModal={setShowModal}
          customClickOutside={() => {
            setSelectedPlatforms(selectedValues);
            setShowModal(false);
          }}
          modalTitle={modalLabel}
          closeLabel="Finished"
          textColor={textColor}
          highlightColor={buttonHighlight}
          modalBg="white"
          desktopMinWidth="30rem"
        >
          <Box wrap direction="row">
            {platforms?.length > 0 && platforms.map((dt) => (
              <Box key={dt} pad={{ right: '1rem', vertical: '0.5rem' }}>
                <StyledModalSelectButton
                  plain
                  selected={selectedValues.includes(dt)}
                  textSize="1rem"
                  onClick={() => handleSelection(dt)}
                  primaryText={textColor}
                  buttonHighlight={buttonHighlight}
                  buttonBg="#F0F6FF"
                  label={dt}
                />
              </Box>
            ))}
          </Box>
          <Box
            width="100%"
            pad={{ top: '1rem' }}
            align="center"
            border={{
              color: '#D0D0D0',
              size: '1px',
              style: 'solid',
              side: 'top',
            }}
          >
            <AppButton
              overrideHover
              width="7.25rem"
              height="3rem"
              onClick={() => {
                setSelectedPlatforms(selectedValues);
                setShowModal(false);
              }}
              level="dynamicLarge"
              color="white"
              bgColor={buttonHighlight}
              fontWeight={600}
              label="Finished"
            />
          </Box>
        </SimpleModal>
      )}
    </Box>
  );
};

PlatformSelector.propTypes = {
  small: PropTypes.bool.isRequired,
  selectedTierKey: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  selectedPlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedPlatforms: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
};

export default PlatformSelector;

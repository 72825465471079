import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import uniqid from 'uniqid';

import {
  Box, Text, Button, Layer,
} from 'grommet';

import {
  Seo, Spinning, AppButton, CopyButton, ActionButton,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { localAsset } from '@Helpers/asset';
import {
  initiateCyclopsCompanyOrganizationsRequest,
  setCyclopsActiveOrganizationRequest,
  submitNewCyclopsOrganizationRequest,
  editCyclopsOrganizationRequest,
  deleteCyclopsOrganizationRequest,
  initiateCyclopsConstantsConfigRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import { FormInputSelector } from '../../../Partial/Product/Cyclops/CyclopsComponents';
import { ModalSelectWrapper } from '../../../Partial/Product/SharedComponents';
import {
  StyledCyclopsHeading, StyledCyclopsContainer, StyledSVG,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const InfoModal = ({
  small, textColor, label, buttonHighlight, modalBg, data,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Box>
      <AppButton
        overrideHover
        onClick={() => setShowModal(true)}
        bgColor={buttonHighlight}
        textColor={textColor}
        level="dynamicSmall"
      >
        <Text size="1rem" color={textColor}>{label}</Text>
      </AppButton>
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
        >
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => null}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            modalBg={modalBg}
            label="CDN Values"
            modalCloseMessage="Close"
          >
            <Box
              direction="column"
              gap="0.5rem"
              pad={{ vertical: '1rem' }}
              height={{ max: '70vh' }}
              width="100%"
            >
              {data.map((val) => (
                <Box key={uniqid()} direction="row" gap="0.5rem" align="center">
                  <Text color={textColor} size="1rem">
                    {val}
                  </Text>
                  <Box flex={false} pad={{ left: '0.25rem', top: '0.15rem' }}>
                    <CopyButton
                      hideTooltip
                      renderKey={val}
                      value={val}
                      color={textColor}
                      hoverColor={buttonHighlight}
                      tooltipColor={buttonHighlight}
                      tooltipPosition="right"
                      data-offset="{'right': 10}"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </ModalSelectWrapper>
        </Layer>
      )}
    </Box>
  );
};

InfoModal.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  modalBg: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const CyclopsOrganizationsPage = ({
  small,
  loading,
  submitLoading,
  location,
  fetchCompanyOrganizations,
  setActiveOrganization,
  submitNewOrganization,
  editOrganization,
  deleteOrganization,
  fetchConstantsConfig,
  constantsConfig = null,
  companyOrganizations = null,
  activeOrganization = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const navigate = useNavigate();
  const [showOrganizationForm, setShowOrganizationForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [activeOrgId, setActiveOrgId] = React.useState(null);
  /* eslint-disable camelcase */
  const [formValues, setFormValues] = React.useState({
    inputValues: {
      name: '',
      piracy_domains: '',
      encoder_outbound_protocol: null,
      encoder_outbound_video_codec: null,
      encoder_outbound_audio_codec: null,
      cyclops_starts_post_abr: false,
      categories: [],
    },
  });

  React.useEffect(() => {
    if (!companyOrganizations || companyOrganizations?.length === 0) fetchCompanyOrganizations();

    if (!constantsConfig) fetchConstantsConfig();
  }, []);

  const handleFormValues = (key, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      inputValues: {
        ...prevState.inputValues,
        [key]: value,
      },
    }));
  };

  const handleResetForm = () => {
    setFormValues({
      inputValues: {
        name: '',
        piracy_domains: '',
        encoder_outbound_protocol: null,
        encoder_outbound_video_codec: null,
        encoder_outbound_audio_codec: null,
        cyclops_starts_post_abr: false,
        categories: [],
      },
    });
  };

  const handleEditOrganization = (orgId) => {
    const organization = companyOrganizations.find(({ Id }) => Id === orgId);
    let piracyDomains = '';
    if (organization.piracyDomains && organization.piracyDomains.length > 0) {
      piracyDomains = organization.piracyDomains.join(', ');
    }

    setFormValues({
      inputValues: {
        name: organization.name,
        piracy_domains: piracyDomains,
        encoder_outbound_protocol: organization.encoderOutboundProtocol,
        encoder_outbound_video_codec: organization.encoderOutboundVideoCodec,
        encoder_outbound_audio_codec: organization.encoderOutboundAudioCodec,
        cyclops_starts_post_abr: organization.cyclopsStartsPostAbr,
        categories: organization.categories,
        id: organization.Id,
      },
    });
    setActiveOrgId(orgId);
    setIsEditing(true);
    setShowOrganizationForm(true);
  };

  const handleDeleteOrganization = (orgId) => {
    setIsEditing(false);
    setShowOrganizationForm(false);
    deleteOrganization(orgId, { Id: orgId }, paths.cyclopsOrganizations, () => {
      fetchCompanyOrganizations();
    });
  };

  const submitFormValues = () => {
    if (isEditing) {
      editOrganization(activeOrgId, formValues.inputValues, paths.cyclopsOrganizations, () => {
        fetchCompanyOrganizations();
      });
    } else {
      submitNewOrganization(formValues.inputValues, paths.cyclopsOrganizations, () => {
        fetchCompanyOrganizations();
      });
    }

    setIsEditing(false);
    setShowOrganizationForm(false);
    handleResetForm();
  };

  const handleEventsRedirect = (orgId, orgName) => {
    if (!activeOrganization || activeOrganization !== orgId) {
      setActiveOrganization({ uid: orgId, name: orgName });
    }
    navigate(paths.cyclopsEventsList.replace(':status', 'live'));
  };

  const {
    darkModeColors: {
      primaryDarkBg, primaryText, secondaryText, containerBg, menuButtonHighlight,
    },
  } = authPagesConfig;

  const requiredKeys = Object.keys(formValues.inputValues).filter((key) => key !== 'piracy_domains');
  const submitDisabled = requiredKeys.some((key) => formValues.inputValues[key] === '' || formValues.inputValues[key] === null);

  const sportsCategories = constantsConfig?.sportsCategories
    && Object.values(constantsConfig.sportsCategories);
  const protocolOptions = constantsConfig?.protocols
    && Object.values(constantsConfig.protocols);
  const videoCodecsOptions = constantsConfig?.videoCodecs
    && Object.values(constantsConfig.videoCodecs);
  const audioCodecsOptions = constantsConfig?.audioCodecs
    && Object.values(constantsConfig.audioCodecs);

  const renderPageContents = (showForm) => {
    if (showForm) {
      return (
        <Box width="100%" justify="center" pad={{ right: small ? '0' : '15%' }} gap="0.5rem">
          <FormInputSelector
            small={small}
            label="Name"
            renderKey="text"
            inputKey="name"
            inputType="text"
            value={formValues.inputValues.name}
            placeholder="Name of this organization"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Piracy domains"
            renderKey="text"
            inputKey="piracy_domains"
            inputType="text"
            value={formValues.inputValues.piracy_domains}
            placeholder="Domains to monitor, seperated by comma"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Categories"
            fieldTitle="Select categories"
            renderKey="modalSelect"
            inputKey="categories"
            value={formValues.inputValues.categories}
            handleFormValues={handleFormValues}
            selectOptions={sportsCategories}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound protocol"
            renderKey="dropdown"
            inputKey="encoder_outbound_protocol"
            value={formValues.inputValues.encoder_outbound_protocol}
            handleFormValues={handleFormValues}
            dropdownOptions={protocolOptions}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound video codec"
            renderKey="dropdown"
            inputKey="encoder_outbound_video_codec"
            value={formValues.inputValues.encoder_outbound_video_codec}
            handleFormValues={handleFormValues}
            dropdownOptions={videoCodecsOptions}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound audio codec"
            renderKey="dropdown"
            inputKey="encoder_outbound_audio_codec"
            value={formValues.inputValues.encoder_outbound_audio_codec}
            handleFormValues={handleFormValues}
            dropdownOptions={audioCodecsOptions}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Cyclops starts post ABR"
            renderKey="checkbox"
            inputKey="cyclops_starts_post_abr"
            value={formValues.inputValues.cyclops_starts_post_abr}
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <Box pad={{ bottom: '1rem' }} justify="center" align="end">
            <AppButton
              overrideHover
              disabled={submitDisabled}
              level="dynamicLarge"
              color="white"
              width="7.5rem"
              bgColor={authPagesConfig.buttonHighlight}
              onClick={() => submitFormValues()}
              fontWeight={600}
              label="Save"
            />
          </Box>
        </Box>
      );
    }
    /* eslint-enable camelcase */

    if (companyOrganizations?.length > 0 && !loading && !submitLoading) {
      return (
        <Box direction="column" background={containerBg} round="10px" pad={{ horizontal: '1.25rem' }}>
          {companyOrganizations.map(({ name, Id, cdnConfigurations }, i) => (
            <Box
              key={Id}
              width="100%"
              direction="row"
              justify="between"
              align="center"
              pad={{ vertical: '1.25rem' }}
              border={(i !== companyOrganizations.length - 1) && {
                side: 'bottom', size: '1px', style: 'solid', color: '#394658',
              }}
            >
              <Text size="1rem" weight={600} color={primaryText}>
                {name}
              </Text>
              <Box direction="row" gap="0.5rem">
                {cdnConfigurations?.fastly?.edgeCode && (
                  <InfoModal
                    small={small}
                    textColor={primaryText}
                    label="CDN Values"
                    buttonHighlight={menuButtonHighlight}
                    modalBg={containerBg}
                    data={cdnConfigurations?.fastly?.edgeCode}
                  />
                )}
                <ActionButton
                  key="edit"
                  small={small}
                  label="Edit"
                  iconPath="images/edit-pencil-icon.svg"
                  textColor={primaryText}
                  hoverColor={menuButtonHighlight}
                  actionHandler={() => handleEditOrganization(Id)}
                />
                <ActionButton
                  key="schedule"
                  small={small}
                  label="Event schedule"
                  iconPath="images/calendar-icon.svg"
                  textColor={primaryText}
                  hoverColor={menuButtonHighlight}
                  actionHandler={() => handleEventsRedirect(Id, name)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <StyledCyclopsContainer width="100%" justify="center" background={containerBg}>
        <Box pad={{ vertical: '1.25rem' }}>
          {(loading || submitLoading) ? (
            <Box flex justify="center" align="center">
              <Spinning size="large" color={authPagesConfig.buttonHighlight} />
            </Box>
          ) : (
            <Text size="1rem" color={secondaryText}>No organizations created yet.</Text>
          )}
        </Box>
      </StyledCyclopsContainer>
    );
  };

  const pageTitle = showOrganizationForm ? 'Create an Organization' : 'Organizations';
  const buttonTitle = showOrganizationForm ? 'Go Back' : 'Create Organization';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={{ horizontal: small ? '2rem' : '3rem', bottom: small ? '2rem' : '3rem', top: small ? '3rem' : '4rem' }}
        direction="column"
        gap="1.5rem"
      >
        <Box direction="column" gap="0.5rem">
          <Box width="100%" direction="row" justify="between" align="center" pad={{ right: small ? '0' : '3rem' }}>
            <StyledCyclopsHeading noMargin level={2} color={primaryText}>
              {pageTitle}
            </StyledCyclopsHeading>
            {constantsConfig && (
              <AppButton
                overrideHover
                level="dynamicLarge"
                color="white"
                width="12rem"
                bgColor={authPagesConfig.buttonHighlight}
                onClick={() => {
                  setIsEditing(false);
                  setActiveOrgId(null);
                  handleResetForm();
                  setShowOrganizationForm(!showOrganizationForm);
                }}
                fontWeight={600}
                label={buttonTitle}
              />
            )}
          </Box>
          {isEditing && (
            <Box direction="column" pad={{ bottom: '1rem' }}>
              <Button plain onClick={() => handleDeleteOrganization(activeOrgId)}>
                <Box direction="row" gap="0.5rem" align="center">
                  <StyledSVG
                    src={localAsset('images/delete-content-icon.svg')}
                    height={small ? '1.1rem' : '1rem'}
                    width={small ? '1.1rem' : '1rem'}
                    style={{ paddingTop: '0.15rem' }}
                    $fillColor={secondaryText}
                  />
                  <Text size="1rem" color={secondaryText}>Delete Organization</Text>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
        <Box pad={{ right: small ? '0' : '3rem' }}>
          {renderPageContents(showOrganizationForm)}
        </Box>
      </Box>

    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyOrganizations: state.cyclops.companyOrganizations,
    activeOrganization: state.organizations.activeCompanyOrganization,
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    constantsConfig: state.cyclops.constantsConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanyOrganizations: initiateCyclopsCompanyOrganizationsRequest,
    setActiveOrganization: setCyclopsActiveOrganizationRequest,
    submitNewOrganization: submitNewCyclopsOrganizationRequest,
    editOrganization: editCyclopsOrganizationRequest,
    deleteOrganization: deleteCyclopsOrganizationRequest,
    fetchConstantsConfig: initiateCyclopsConstantsConfigRequest,
  }, dispatch);
}

CyclopsOrganizationsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    piracyDomains: PropTypes.arrayOf(PropTypes.string),
    encoderOutboundProtocol: PropTypes.string,
    encoderOutboundVideoCodec: PropTypes.string,
    encoderOutboundAudioCodec: PropTypes.string,
    cyclopsStartsPostAbr: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    vodFilename: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    watermarkDecoders: PropTypes.arrayOf(PropTypes.string),
    cdnConfigurations: PropTypes.shape({
      fastly: PropTypes.shape({
        apiKey: PropTypes.string,
        serviceId: PropTypes.string,
        dictionaryId: PropTypes.string,
        edgeCode: PropTypes.arrayOf(PropTypes.string),
      }),
      cloudfront: PropTypes.shape({
        awsAccessKeyId: PropTypes.string,
        awsSecretAccessKey: PropTypes.string,
        awsRegion: PropTypes.string,
        dynamoDbTableName: PropTypes.string,
        dynamoDbPrimaryKeyName: PropTypes.string,
        dynamoDbValueKeyName: PropTypes.string,
      }),
    }),
  })),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  fetchCompanyOrganizations: PropTypes.func.isRequired,
  setActiveOrganization: PropTypes.func.isRequired,
  submitNewOrganization: PropTypes.func.isRequired,
  editOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  fetchConstantsConfig: PropTypes.func.isRequired,
  constantsConfig: PropTypes.objectOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(CyclopsOrganizationsPage),
);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import {
  Seo, InfoNotice,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { DomainListTablePartial, PlatformSelectTablePartial } from '@Components/Partial/Product/CompanySettings';
import { paths } from '@Components/configs';

import {
  initiateCompanySettingsRequest,
  initiateSingleCompanySettingRequest,
  clearSingleCompanySettingRequest,
  updateCompanyDomainRequest,
} from '@Actions';


const CompanySettingsPage = ({
  small,
  location,
  loading,
  submitLoading,
  fetchCompanySettings,
  fetchCompanySetting,
  clearCompanySetting,
  updateCompanyDomain,
  companySettings = null,
  settingData = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const { id: currentSetting } = useParams();
  const [resetKey, setResetKey] = React.useState(uniqid());

  React.useEffect(() => {
    setResetKey(uniqid());
    fetchCompanySetting(currentSetting);

    if (!companySettings) fetchCompanySettings();

    return () => {
      clearCompanySetting();
    };
  }, [currentSetting]);

  const currentSettingData = _.find(companySettings, { api: currentSetting });
  const settingHint = currentSettingData?.hint;
  const pageTitle = currentSettingData?.display;

  const isPlatformSetting = currentSetting.includes('platform');

  const handleDomainUpdate = (value, preValue = null, action = 'push') => {
    let requestData = {};

    /* eslint-disable camelcase */
    switch (action) {
      case 'edit':
        requestData = { value, pre_value: preValue, action };
        break;
      case 'delete':
        requestData = { pre_value: value, action };
        break;
      default:
        requestData = { value, action };
        break;
    }

    const body = { data: [requestData] };
    updateCompanyDomain(currentSetting, body, paths.settings.replace(':id', currentSetting), () => fetchCompanySetting(currentSetting));
  };

  const handlePlatformUpdate = (prevValues, newValues) => {
    const updates = [];

    /* eslint-disable camelcase */
    prevValues.forEach((value) => {
      if (!newValues.includes(value)) {
        updates.push({ pre_value: value, action: 'delete' });
      }
    });
    /* eslint-enable camelcase */

    newValues.forEach((value) => {
      if (!prevValues.includes(value)) {
        updates.push({ value, action: 'push' });
      }
    });

    const body = { data: updates };
    updateCompanyDomain(currentSetting, body, paths.settings.replace(':id', currentSetting), () => fetchCompanySetting(currentSetting));
  };

  let hintHeader = 'Information';

  if (currentSetting.includes('dmca')) {
    hintHeader = 'DMCA Notice Information';
  }

  if (currentSetting.includes('review')) {
    hintHeader = 'Review Tool Information';
  }

  const renderContent = () => {
    if (isPlatformSetting) {
      return (
        <PlatformSelectTablePartial
          key={resetKey}
          small={small}
          loading={loading || submitLoading}
          options={settingData?.values}
          selectedOptions={settingData?.value}
          updateSelections={(prevValues, newValues) => (
            handlePlatformUpdate(prevValues, newValues)
          )}
          pageTitle={pageTitle}
          authPagesConfig={authPagesConfig}
        />
      );
    }

    return (
      <DomainListTablePartial
        key={resetKey}
        small={small}
        loading={loading || submitLoading}
        domainData={settingData?.value}
        updateDomain={(value, preValue, action) => handleDomainUpdate(value, preValue, action)}
        pageTitle={pageTitle}
        headerMap={[
          { uid: 'domain', display: 'Domain', size: small ? '75%' : '85%' },
          { uid: 'actions', display: 'Actions', size: small ? '25%' : '15%' },
        ]}
        authPagesConfig={authPagesConfig}
      />
    );
  };

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: pageTitle,
        subTitle: 'Settings',
        textColor: 'white',
      }}
    >
      <Seo />
      <Box
        flex
        direction="column"
        background="#F1F2FC"
        pad={{ vertical: '1.5rem' }}
        height="100%"
        gap="1rem"
      >
        {settingHint && (
          <InfoNotice
            header={hintHeader}
            headerColor={authPagesConfig.focusHighlight}
            text={settingHint}
            textColor={authPagesConfig.primaryText}
            borderColor="#61AFEE"
            backgroundColor={authPagesConfig.hoverColor}
          />
        )}
        {renderContent()}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    settingData: state.companySettings.companySettingData,
    companySettings: state.companySettings.companySettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanySettings: initiateCompanySettingsRequest,
    fetchCompanySetting: initiateSingleCompanySettingRequest,
    clearCompanySetting: clearSingleCompanySettingRequest,
    updateCompanyDomain: updateCompanyDomainRequest,
  }, dispatch);
}

CompanySettingsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  fetchCompanySettings: PropTypes.func.isRequired,
  fetchCompanySetting: PropTypes.func.isRequired,
  clearCompanySetting: PropTypes.func.isRequired,
  updateCompanyDomain: PropTypes.func.isRequired,
  companySettings: PropTypes.arrayOf(PropTypes.shape({
    api: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    hint: PropTypes.string,
  })),
  settingData: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string.isRequired),
    values: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(CompanySettingsPage));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box } from 'grommet';
import { ShareRounded } from 'grommet-icons';
import ReactTooltip from 'react-tooltip';

import { Seo, ActionButton } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  refreshEmbedAccessDataRequest,
  clearEmbedAccessDataRequest,
} from '@Actions';
import Mixpanel from '@Helpers/mixpanel';
import { secondsSinceEpoch, baseServerUrl } from '@Helpers/util';
import { DomoEmbedPartial } from '@Components/Partial/Product/Overview';


export const CopyLinkButton = ({
  small,
  textColor,
  highlightColor,
  location,
  renderKey,
  pageTitle = null,
  embedId = null,
  applyFilter = false,
  companyId = null,
}) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  if (!embedId) return null;

  const now = Math.floor(Date.now() / 1000);
  const ninetyDaysInSeconds = 90 * 24 * 60 * 60;
  const expiresAt = now + ninetyDaysInSeconds;

  const dataString = `embedId=${embedId}&expiresAt=${expiresAt}&applyCompanyFilter=${applyFilter}&companyId=${companyId}`;
  const encodedString = btoa(dataString);

  return (
    <Box>
      <ActionButton
        data-for={`${renderKey}-tooltip`}
        data-tip={copied ? 'Copied to clipboard!' : 'Link expires in 90 days'}
        key={copied ? `${renderKey}-copied` : renderKey}
        small={small}
        label="Copy share link"
        icon={<ShareRounded color={textColor} size="1rem" />}
        textColor={textColor}
        hoverColor={highlightColor}
        style={{ backgroundColor: 'white' }}
        actionHandler={() => {
          navigator.clipboard.writeText(`${window.location.origin}/shared-report?${encodedString}`);
          Mixpanel.track('report_share_link_generated', {
            location: location.pathname,
            page: pageTitle,
            embedId,
          });
          setCopied(true);
        }}
      />
      <ReactTooltip
        id={`${renderKey}-tooltip`}
        key={copied ? `${renderKey}-tooltip-copied` : `${renderKey}-tooltip`}
        data-offset="{'left': 10}"
        place="left"
        effect="solid"
        tooltip
      />
    </Box>
  );
};

CopyLinkButton.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  renderKey: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  embedId: PropTypes.string,
  applyFilter: PropTypes.bool,
  companyId: PropTypes.number,
};

const PiracyOverviewPage = ({
  small,
  loading,
  location,
  authPagesConfig = null,
  embedConfig = null,
  customReports = null,
  cyclopsConfig = null,
  refreshAccessData,
  clearAccessData,
  companyId,
  accessToken = null,
  accessTokenExpiration = null,
}) => {
  const [embedToken, setEmbedToken] = React.useState(null);
  const [embedData, setEmbedData] = React.useState({
    embedId: null,
    embedTitle: null,
    applyCompanyFilter: null,
    pageTitle: null,
  });

  React.useEffect(() => {
    if (embedConfig) {
      setEmbedData({
        embedId: embedConfig.embedId,
        embedTitle: embedConfig.embedTitle,
        applyCompanyFilter: embedConfig.applyCompanyFilter,
        pageTitle: 'Piracy Overview',
      });
    }
  }, [embedConfig]);

  const fetchServerEmbedToken = async (useToken) => {
    const options = { method: 'GET' };
    let embedParams = `token=${useToken}&embedId=${embedData.embedId}`;

    if (embedData.applyCompanyFilter) {
      embedParams += `&companyId=${companyId}`;
    }

    const response = await fetch(`${baseServerUrl()}api/embedToken?${embedParams}`, options);
    const responseContent = await response.text();
    setEmbedToken(responseContent);
  };

  React.useEffect(() => {
    if (!accessToken || accessTokenExpiration - 60 > secondsSinceEpoch()) {
      refreshAccessData();
    }

    return () => {
      clearAccessData();
    };
  }, []);

  React.useEffect(() => {
    if (accessToken) {
      fetchServerEmbedToken(accessToken);
    }
  }, [accessToken]);

  const { primaryText, buttonHighlight } = authPagesConfig;

  return (
    <AuthNavWrapper
      smallBanner
      hideFooter
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: embedData.pageTitle || 'Loading report...',
        subTitle: 'Content protection hub',
        textColor: 'white',
        bannerChildren: (
          <Box
            direction="row"
            justify="center"
            align="center"
            gap="1rem"
          >
            <CopyLinkButton
              small={small}
              textColor={primaryText}
              highlightColor={buttonHighlight}
              location={location}
              pageTitle={embedData.pageTitle}
              embedId={embedData.embedId}
              applyFilter={embedData.applyCompanyFilter}
              companyId={companyId}
              renderKey="piracy-overview"
            />
          </Box>
        ),
        bannerChildrenPosition: 'right',
      }}
    >
      <Seo />
      <Box flex background="#F1F2FC" pad={{ vertical: '1rem', horizontal: small ? '0.5rem' : '5vw' }} height="100%">
        <DomoEmbedPartial
          loading={loading}
          textColor={primaryText}
          highlightColor={buttonHighlight}
          embedId={embedData.embedId}
          embedToken={embedToken}
        />
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    accessToken: state.productEmbed.embedAccessToken,
    accessTokenExpiration: state.productEmbed.embedAccessTokenExpiration,
    loading: state.fetchLoader.dataLoading,
    companyId: state.auth.companyId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    refreshAccessData: refreshEmbedAccessDataRequest,
    clearAccessData: clearEmbedAccessDataRequest,
  }, dispatch);
}

PiracyOverviewPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  embedConfig: PropTypes.shape({
    embedId: PropTypes.string.isRequired,
    secondaryEmbedId: PropTypes.string,
    applyCompanyFilter: PropTypes.bool,
  }),
  refreshAccessData: PropTypes.func.isRequired,
  clearAccessData: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  accessTokenExpiration: PropTypes.number,
  companyId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(PiracyOverviewPage));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Case from 'case';

import {
  Box, Text, Image, Button,
} from 'grommet';

import {
  Seo, ProductRowStats, InfoNotice, UnderConstruction, SimpleModal,
} from '@Components/Control';
import { ListSearchesTablePartial } from '@Components/Partial/Product/ListSearches';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { paths } from '@Components/configs';
import { buildDateLabel } from '@Helpers/util';
import {
  initiateProductListSearchesRequest,
  clearProductListSearchesRequest,
  initiateProductListSearchesAddedRequest,
  clearProductListSearchesAddedRequest,
  initiateProductListSearchesMetaRequest,
  initiateProductListSearchesStatsRequest,
  clearProductListSearchesStatsRequest,
  submitSearchFeatureRequest,
} from '@Actions';
import StyledAddedSearchContainer from './StyledListSearches';


const BannerDisplayContainer = ({
  children, title, tagBg, containerBg,
}) => (
  <Box direction="column" width="100%">
    <Box
      background={tagBg}
      round={{ size: '8px', corner: 'top' }}
      width="fit-content"
      pad={{ horizontal: '0.5rem', vertical: '0.2rem' }}
    >
      <Text color="white" size="0.85rem" weight={600}>{title}</Text>
    </Box>
    <StyledAddedSearchContainer background={containerBg} direction="column" pad="1rem" justify="between">
      {children}
    </StyledAddedSearchContainer>
  </Box>
);

BannerDisplayContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  tagBg: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
};

const BannerChildren = ({
  small,
  bgColor,
  addedTagBg,
  summaryTagBg,
  dashboardPath,
  textColor,
  seeDetailsIcon,
  timerIcon,
  focusHighlight,
  buttonHighlight,
  addedSearch = null,
  summaryData = null,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const renderAddedSearch = ({
    name, startDate, endDate, resultCount,
  }) => (
    <BannerDisplayContainer
      title="Added"
      tagBg={addedTagBg}
      containerBg={bgColor}
    >
      <Box direction="column" gap="0.5rem">
        <Box width="100%" direction="row" justify="between">
          <Text color={textColor} weight={600} size="1rem">
            {name}
          </Text>
          <Box
            direction="row"
            round="12.5px"
            pad={{ vertical: '0.2rem', horizontal: '0.5rem' }}
            background="#F0FAF7"
            align="center"
            justify="center"
            gap="0.35rem"
            width="fit-content"
            alignSelf="end"
          >
            <Box flex={false} width="0.9rem" height="0.9rem">
              <Image
                src={timerIcon}
                alt="Recently added search"
                fit="contain"
                width="0.9rem"
                height="0.9rem"
              />
            </Box>
            <Text color="#00945E" weight={600} size="0.9rem">
              Running
            </Text>
          </Box>
        </Box>
        <Text size="1rem" color={textColor}>
          {resultCount ? `${resultCount} infringements found` : '0 infringements found'}
        </Text>
        <Box width="100%" direction="row" justify="between">
          <Text size="1rem" color={textColor}>
            {buildDateLabel(startDate, endDate, true)}
          </Text>
          <Button plain href={dashboardPath(addedSearch.id)}>
            <Box direction="row" align="center" gap="0.5rem">
              <Text size="1rem" color={focusHighlight}>
                See details
              </Text>
              <Box flex={false} width="1rem" height="1rem">
                <Image
                  src={seeDetailsIcon}
                  alt="See more details"
                  fit="contain"
                  width="1rem"
                  height="1rem"
                />
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    </BannerDisplayContainer>
  );

  const renderSummaryData = ({ uid, value }) => {
    const displayTitle = Case.title(uid);
    let displayValue = value.join(', ');
    let restValues = null;

    if (value.length >= 10) {
      const firstValues = value.slice(0, 10);
      restValues = value.slice(10);
      displayValue = `${firstValues.join(', ')} + ${restValues.length} more`;
    }

    return (
      <BannerDisplayContainer
        title="Summary"
        tagBg={summaryTagBg}
        containerBg={bgColor}
      >
        <Box direction="row" gap="0.5rem">
          <Text color={textColor} weight={600} size="1rem" style={{ lineHeight: '1.5' }}>
            {displayTitle}
            {': '}
            <Text size="1rem" weight="normal" color={textColor}>
              {displayValue}
              {restValues && (
                <Box width="fit-content" pad={{ left: '0.5rem' }} style={{ display: 'inline-block' }}>
                  <Button plain onClick={() => setShowModal(true)}>
                    <Text size="0.9rem" weight={500} color={buttonHighlight}>
                      (View all)
                    </Text>
                  </Button>
                </Box>
              )}
            </Text>
          </Text>
        </Box>
        {showModal && (
          <SimpleModal
            small={small}
            toggleModal={setShowModal}
            modalTitle={displayTitle}
            closeLabel="Finished"
            textColor={textColor}
            highlightColor={buttonHighlight}
            modalBg="white"
            titleSize="1.2rem"
          >
            <Box gap="small" width="100%">
              <Text size="1.2rem" color={textColor} style={{ whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                {value.join(', ')}
              </Text>
            </Box>
          </SimpleModal>
        )}
      </BannerDisplayContainer>
    );
  };

  return (
    <Box direction="column" gap="1rem" width={small ? '100%' : '65%'}>
      {addedSearch && renderAddedSearch(addedSearch)}
      {summaryData && summaryData?.value?.length > 0 && renderSummaryData(summaryData)}
    </Box>
  );
};

BannerChildren.propTypes = {
  small: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  addedTagBg: PropTypes.string.isRequired,
  addedSearch: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    resultCount: PropTypes.number.isRequired,
  }),
  dashboardPath: PropTypes.func.isRequired,
  seeDetailsIcon: PropTypes.string.isRequired,
  timerIcon: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  summaryTagBg: PropTypes.string.isRequired,
  summaryData: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

const ListSearchesPage = ({
  small,
  loading,
  location,
  fetchListData,
  clearListData,
  fetchListAdded,
  clearListAdded,
  fetchListMeta,
  fetchListStats,
  clearListStats,
  submitSearchFeature,
  listSearchesData = null,
  listSearchesAdded = null,
  listSearchesMeta = null,
  listSearchesStats = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const [query, setQuery] = React.useState({
    paging: {
      to: 1,
      size: 10,
    },
  });
  const pageParams = useParams();
  const currentListUid = pageParams.status;
  const scope = currentListUid === 'all' ? null : currentListUid;
  const underMaintenance = authPagesConfig && authPagesConfig.disableSearchFeatures;
  const dashboardPath = (searchId) => paths.productSearchResults.replace(':id', searchId);

  React.useEffect(() => {
    const useQuery = scope ? { ...query, scope } : { ...query };
    fetchListData(useQuery);
    fetchListStats();
    fetchListMeta();

    if (scope === 'live' && !listSearchesAdded) fetchListAdded({ scope: 'added' });

    return () => {
      clearListData();
      clearListAdded();
      clearListStats();
    };
  }, [currentListUid]);

  React.useEffect(() => {
    const useQuery = scope ? { ...query, scope } : { ...query };
    fetchListData(useQuery);
  }, [query]);

  const handleQuery = (parentKey, updateKey, value) => {
    const prevQuery = { ...query };
    prevQuery[parentKey][updateKey] = value;

    setQuery(prevQuery);
  };

  const handleSort = (sortValue, sortDirection) => {
    const prevQuery = { ...query };

    if (!prevQuery.sort && sortValue) {
      prevQuery.sort = {};
    }

    if (sortValue === null && prevQuery.sort) {
      delete prevQuery.sort;
    } else if (sortValue) {
      prevQuery.sort = {
        name: sortValue,
        direction: sortDirection,
      };
    }

    setQuery(prevQuery);
  };

  const handleFilter = (
    filterKey, filterValue, filterPredicate, seperateValues = false, reset = false,
  ) => {
    const prevQuery = { ...query };

    if (reset) {
      delete prevQuery.filters;
      return setQuery(prevQuery);
    }

    if (!prevQuery.filters && filterValue) {
      prevQuery.filters = [];
    }

    const filterIndex = _.findIndex(prevQuery.filters, ['name', filterKey]);

    if (!filterValue && prevQuery.filters) {
      delete prevQuery.filters[filterIndex];
    } else if (filterValue && !seperateValues) {
      const newFilter = {
        name: filterKey,
        value: filterValue,
        predicate: filterPredicate,
      };

      if (filterIndex !== -1) {
        prevQuery.filters[filterIndex] = { ...newFilter };
      } else {
        prevQuery.filters.push(newFilter);
      }
    } else if (filterValue && seperateValues) {
      const addFilters = prevQuery.filters.concat(filterValue);
      prevQuery.filters = addFilters;
    }

    if (prevQuery.filters && prevQuery.filters.length === 0) {
      delete prevQuery.filters;
    }

    return setQuery(prevQuery);
  };

  const handleSearchFeature = (id, feature) => {
    let redirectUid = currentListUid;
    switch (feature) {
      case 'pause':
        redirectUid = 'paused';
        break;
      case 'resume':
        redirectUid = 'live';
        break;
      default:
        break;
    }

    const redirectPath = paths.productListSearches.replace(':status', redirectUid);
    submitSearchFeature(id, feature, redirectPath);
  };

  const pageTitle = `${Case.capital(currentListUid)} Searches`;
  const headerMap = [
    {
      display: 'Title',
      uid: 'name',
      size: '27%',
    },
    {
      display: currentListUid === 'paused' ? 'Infringements' : 'Infringements Found',
      uid: 'resultCount',
      size: '20%',
    },
    {
      display: 'Date',
      uid: 'date',
      size: '28%',
    },
    {
      display: 'Status',
      uid: 'status',
      size: '25%',
    },
  ];

  let addedSearch = null;
  let summaryData = null;

  if (listSearchesAdded?.data?.totalCount === 1) {
    [addedSearch] = listSearchesAdded.data.searches;
  }

  if (listSearchesData?.data?.summary) {
    [summaryData] = listSearchesData.data.summary;
  }

  const padding = small ? '1.5rem' : '2rem';
  const showBannerChildren = addedSearch
    || (summaryData && summaryData.value?.length > 0);
  const resultsPerPageOptions = [10, 15, 20, 25];
  const displayStats = [];

  if (currentListUid === 'live') {
    displayStats.push('running', 'scheduled');
  } else if (currentListUid === 'all') {
    displayStats.push('running', 'scheduled', 'paused', 'completed');
  } else {
    displayStats.push(currentListUid);
  }

  const renderPageContent = () => {
    if (underMaintenance) {
      return (
        <Box flex align="center" justify="center" background="white">
          <UnderConstruction
            imageUrl={authPagesConfig.constructionImage}
            buttonHighlight={authPagesConfig.buttonHighlight}
            header="Under Maintenance"
            message="System currently undergoing maintenance. Please check back later."
          />
        </Box>
      );
    }

    return (
      <Box flex background="#F1F2FC" pad={listSearchesStats ? { top: '0', bottom: padding } : { vertical: padding }}>
        {listSearchesStats && displayStats.length > 0 && (
          <Box width="100%" direction="row" justify="end" pad={{ vertical: '0.65rem', right: '1.5rem' }}>
            {displayStats.map((uid, i) => (
              <ProductRowStats
                key={uid}
                isLast={i === displayStats.length - 1}
                label={`${Case.capital(uid)}:`}
                stat={listSearchesStats[uid]}
                color={authPagesConfig.primaryText}
              />
            ))}
          </Box>
        )}
        {(currentListUid === 'paused') && (
          <InfoNotice
            header="A search can be paused for up to 30 days."
            headerColor={authPagesConfig.focusHighlight}
            text="You can resume or delete a search before then. After 30 days, paused searches will be moved to completed."
            textColor={authPagesConfig.primaryText}
            borderColor="#61AFEE"
            backgroundColor={authPagesConfig.hoverColor}
          />
        )}
        <ListSearchesTablePartial
          small={small}
          listSearchesData={listSearchesData}
          authPagesConfig={authPagesConfig}
          listSearchesMeta={listSearchesMeta}
          handleQuery={(parentKey, updateKey, value) => handleQuery(parentKey, updateKey, value)}
          handleSort={(sortValue, sortDirection) => handleSort(sortValue, sortDirection)}
          handleFilter={(key, value, predicate, seperateValues, reset) => (
            handleFilter(key, value, predicate, seperateValues, reset)
          )}
          resultsPerPageOptions={resultsPerPageOptions}
          currentListUid={currentListUid}
          dashboardPath={(searchId) => dashboardPath(searchId)}
          submitSearchFeature={(id, feature) => handleSearchFeature(id, feature)}
          searchQuery={query}
          pageTitle={pageTitle}
          headerMap={headerMap}
          loading={loading}
        />
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: pageTitle,
        subTitle: 'Blacklight',
        textColor: 'white',
        bannerChildren: showBannerChildren && (
          <BannerChildren
            bgColor="white"
            small={small}
            textColor={authPagesConfig.primaryText}
            focusHighlight={authPagesConfig.focusHighlight}
            buttonHighlight={authPagesConfig.buttonHighlight}
            seeDetailsIcon={authPagesConfig.icons.seeDetailsIcon}
            timerIcon={authPagesConfig.icons.timerClockIcon}
            addedTagBg={authPagesConfig.numberHighlight}
            addedSearch={addedSearch}
            dashboardPath={(searchId) => dashboardPath(searchId)}
            summaryTagBg="#22684B"
            summaryData={summaryData}
          />
        ),
        bannerChildrenPosition: 'bottom',
      }}
    >
      <Seo />
      {renderPageContent()}
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    listSearchesData: state.productList.productListSearches,
    listSearchesAdded: state.productList.productListSearchesAdded,
    listSearchesMeta: state.productList.productListSearchesMeta,
    listSearchesStats: state.productList.productListSearchesStats,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchListData: initiateProductListSearchesRequest,
    clearListData: clearProductListSearchesRequest,
    fetchListAdded: initiateProductListSearchesAddedRequest,
    clearListAdded: clearProductListSearchesAddedRequest,
    fetchListMeta: initiateProductListSearchesMetaRequest,
    fetchListStats: initiateProductListSearchesStatsRequest,
    clearListStats: clearProductListSearchesStatsRequest,
    submitSearchFeature: submitSearchFeatureRequest,
  }, dispatch);
}

ListSearchesPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchListData: PropTypes.func.isRequired,
  clearListData: PropTypes.func.isRequired,
  fetchListAdded: PropTypes.func.isRequired,
  clearListAdded: PropTypes.func.isRequired,
  fetchListMeta: PropTypes.func.isRequired,
  fetchListStats: PropTypes.func.isRequired,
  clearListStats: PropTypes.func.isRequired,
  submitSearchFeature: PropTypes.func.isRequired,
  listSearchesData: PropTypes.shape({
    data: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      summary: PropTypes.arrayOf(PropTypes.shape({
        uid: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired),
      searches: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        priority: PropTypes.string,
        resultCount: PropTypes.number.isRequired,
        features: PropTypes.arrayOf(PropTypes.string.isRequired),
      })).isRequired,
    }),
  }),
  listSearchesAdded: PropTypes.shape({
    data: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      searches: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        resultCount: PropTypes.number.isRequired,
      })).isRequired,
    }),
  }),
  listSearchesMeta: PropTypes.shape({
    sort: PropTypes.shape({
      name: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      direction: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        validValues: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
        validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired).isRequired,
    }).isRequired,
    status: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired,
  }),
  listSearchesStats: PropTypes.shape({
    running: PropTypes.number.isRequired,
    scheduled: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    paused: PropTypes.number.isRequired,
    canceled: PropTypes.number.isRequired,
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    numberHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      timerClockIcon: PropTypes.string.isRequired,
      searchPriorityIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(ListSearchesPage));

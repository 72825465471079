import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box, Text, Layer,
} from 'grommet';

import { Spinning } from '@Components/Control';


const StyledLayer = styled(Layer)`background: transparent;`;

const SubmissionLoader = ({
  textColor,
  loaderColor,
  message = null,
  children = null,
  contentWidth = '15rem',
  modalBg = 'white',
}) => {
  const renderContent = () => {
    if (message) {
      return <Text size="1rem" color={textColor}>{message}</Text>;
    }

    return children;
  };

  return (
    <StyledLayer
      responsive={false}
      animate={false}
      onEsc={() => null}
      onClickOutside={() => null}
    >
      <Box
        align="center"
        justify="center"
        direction="column"
        pad="1rem"
        gap="1rem"
        background={modalBg}
        width={contentWidth}
        round="10px"
      >
        <Spinning size="large" color={loaderColor} />
        {renderContent()}
      </Box>
    </StyledLayer>
  );
};

SubmissionLoader.propTypes = {
  textColor: PropTypes.string.isRequired,
  loaderColor: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.node,
  contentWidth: PropTypes.string,
  modalBg: PropTypes.string,
};

export default SubmissionLoader;

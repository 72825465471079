import styled from 'styled-components';

import { Tab, Layer } from 'grommet';

const StyledProductTypeTab = styled(Tab)`
  &:first-child {
    margin: 0;
  }

  &[aria-selected="true"] {
    div {
      background-color: white;
      border-color: #788af7;
    }

    span {
      color: #788af7;
    }
  }

  div {
    border: 1px solid #EBECF3;
    background-color: #EBECF3;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }

    span {
      color: #84878d;
      padding-left: ${({ isSmall }) => (isSmall ? '0.75rem' : '1.25rem')};
      padding-right: ${({ isSmall }) => (isSmall ? '0.75rem' : '1.25rem')};
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-weight: 700;
    }
  }

  &:hover div {
    border-color: #788af7;
    box-shadow: 0px 0px 0px 1px #788af7;
  }
`;

const StyledLayer = styled(Layer)`background: transparent;`;

export {
  StyledProductTypeTab,
  StyledLayer,
};

import {
  NOTICE_LOADED,
  NOTICE_CLEAR,
  CUSTOM_NOTICE_LOADED,
  CUSTOM_NOTICE_CLEAR,
  TOUR_STARTED,
  TOUR_ENDED,
} from './actionTypes';

// Reducer corresponding actions
function noticeLoaded(message, type) {
  return {
    type: NOTICE_LOADED,
    payload: { message, type },
  };
}

function noticeClear() {
  return { type: NOTICE_CLEAR };
}

function customNoticeLoaded(message) {
  return {
    type: CUSTOM_NOTICE_LOADED,
    payload: { message },
  };
}

function customNoticeClear() {
  return { type: CUSTOM_NOTICE_CLEAR };
}

function tourStarted() {
  return { type: TOUR_STARTED };
}

function tourEnded() {
  return { type: TOUR_ENDED };
}

// Public components actions
export function closeNoticeRequest() {
  return (dispatch) => {
    dispatch(noticeClear());
  };
}

export function arriveNotice(message, type = 'warning', clear = true) {
  return (dispatch) => {
    if (clear) dispatch(noticeClear());
    dispatch(noticeLoaded(message, type));
  };
}

export function closeCustomNoticeRequest() {
  return (dispatch) => {
    dispatch(customNoticeClear());
  };
}

export function arriveCustomNotice(message) {
  return (dispatch) => {
    dispatch(customNoticeLoaded(message));
  };
}

export function startTourRequest() {
  return (dispatch) => {
    dispatch(tourStarted());
  };
}

export function endTourRequest() {
  return (dispatch) => {
    dispatch(tourEnded());
  };
}
